import {
  Transcript,
  TranscriptEvent,
  TranscriptItemType,
  TranscriptResult,
} from 'amazon-chime-sdk-js'
import React, {useState, useContext, ReactNode, useEffect, useRef} from 'react'
import {textTranslation} from '../../../Utils/api'

export type TranscriptContextType = {
  meetingtranscriptevent: TranscriptEvent | undefined
  addTranscript: (data: TranscriptEvent | undefined) => void
  selectedLanguage: (language: string) => void
  transcripts: any
  targetLanguageCode: string
}

type Props = {
  children: ReactNode
}

const TranscriptContext = React.createContext<TranscriptContextType | null>(null)

const TranscriptProvider = ({children}: Props) => {
  const [meetingtranscriptevent, setMeetingtranscriptevent] = useState<TranscriptEvent | undefined>(
    undefined
  )
  const [partialTranscript, setPartialTranscript] = useState<string | null>(' ')
  const [transcripts, setTranscripts] = useState<any>([])
  const [targetLanguageCode, setTargetLanguageCode] = useState<any>('en-US')
  const targetLanguageCodeRef = useRef(targetLanguageCode)

  useEffect(() => {
    targetLanguageCodeRef.current = targetLanguageCode
  }, [targetLanguageCode])

  const addTranscriptChunk = async (result: TranscriptResult) => {
    // create lines from chunk based on when new speaker starts talking
    const lines: any[] = []

    let startTimeMs: number | null = null
    let content = ''
    let attendee: any = null
    for (const item of result.alternatives[0].items) {
      if (!startTimeMs) {
        content = item.content
        attendee = item.attendee
        startTimeMs = item.startTimeMs
      } else {
        if (item.attendee.attendeeId != attendee.attendeeId) {
          lines.unshift({
            startTimeMs: startTimeMs,
            text: content,
            speaker: attendee.externalUserId,
          })
          content = item.content
          attendee = item.attendee
          startTimeMs = item.startTimeMs
        } else {
          if (item.type === TranscriptItemType.PUNCTUATION) {
            content = content + item.content
          } else {
            content = content + ' ' + item.content
          }
        }
      }
    }

    lines.unshift({
      startTimeMs: startTimeMs,
      text: content,
      speaker: attendee.externalUserId,
      isTranslation: false,
    })

    if (result.isPartial) {
      setPartialTranscript(
        lines.map((line) => `${line.speaker ? `${line.speaker} ` : ''}${line.text}`).join(' ')
      )
    } else {
      setPartialTranscript(null)
      let tempLines: any = []
      for (let i = 0; i < lines.length; i++) {
        if (lines[i]?.isTranslation) {
          tempLines = [...tempLines, lines[i]]
        } else {
          const textData = await textTranslation(
            lines[i]?.text,
            targetLanguageCodeRef?.current ?? 'en-US'
          )
          tempLines = [
            ...tempLines,
            {...lines[i], isTranslation: true, text: textData?.TranslatedText ?? lines[i]?.text},
          ]
        }
      }
      setTranscripts((t: any) => [...tempLines, ...t])
    }
  }
  const addTranscript = async (transcriptEvent: TranscriptEvent | undefined): Promise<void> => {
    if (transcriptEvent && transcriptEvent instanceof Transcript) {
      for (const result of transcriptEvent.results) {
        await addTranscriptChunk(result)
      }
    }
    // setMeetingtranscriptevent(tempData)
  }

  const selectedLanguage = (language: string) => {
    setTargetLanguageCode(language)
  }

  const providerValue = {
    meetingtranscriptevent,
    addTranscript,
    transcripts,
    selectedLanguage,
    targetLanguageCode,
  }

  return <TranscriptContext.Provider value={providerValue}>{children}</TranscriptContext.Provider>
}

const useTranscript = (): TranscriptContextType => {
  const context = useContext(TranscriptContext)
  if (!context) {
    throw Error('Use useTranscript in TranscriptProvider')
  }
  return context
}

export {TranscriptProvider, useTranscript}
