import React from 'react'

const HoldMusic = () => {
  return (
    <main>
      <section className='settings'>
        <div className='container-fluid px-0'>
          <div className='settings-wrapper'>
            <div className='settings-content' role='presentation'>
              <div className='bar' role='presentation'>
                <button type='button' className='btn button-light-primary'>
                  <span className='fa fa-chevron-left me-2' aria-hidden='true'></span>All settings
                </button>
              </div>
              <div className='panel panel--settings' aria-labelledby='contact_details_heading'>
                <div className='container' role='presentation'>
                  <h2 className='panel__header'>Hold music</h2>
                  <p role='note'>
                    This is the music played to participants in your meeting room while they are
                    waiting for the meeting to start or when they are the only person in the
                    meeting.
                  </p>
                  <p>
                    You can choose from a variety of different types of hold music or you can upload
                    your own file.
                  </p>
                  <h4 className='mt-3 fw-normal'>Curated music playlist</h4>
                  <div className='row'>
                    <div className='col-md-12'>
                      <div className='form-group'>
                        <select
                          id='plan_type'
                          className='form-select valid'
                          name='plan_type'
                          style={{
                            width: '90%',
                            borderRadius: '20px',
                          }}
                          aria-invalid='false'
                        >
                          <option value='Original Composition'>Original Composition</option>
                          <option value='Silence'>Silence</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className='form-group'>
                    <button className='btn btn-primary'>Save</button>
                  </div>
                  <h4 className='mt-3 fw-normal'>Upload a music file </h4>
                  <p>
                    Upload your own MP3 music file to be played for the Hold Music in your meetings.{' '}
                  </p>
                  <div className='form-group'>
                    <button className='btn btn-primary'>Upload Custom Music</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  )
}

export default HoldMusic
