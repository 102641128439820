import React, {useState} from 'react'
import {Form, Modal, Button} from 'react-bootstrap'
import OTPInput from 'react-otp-input'

const OTPModal = ({
  otpModal,
  setOtpModal,
  handleSubmit,
}: {
  otpModal: boolean
  setOtpModal: React.Dispatch<React.SetStateAction<boolean>>
  handleSubmit: (otp: string) => Promise<void>
}) => {
  const [otp, setOtp] = useState('')

  const handleChange = (otp: any) => {
    setOtp(otp)
  }

  const handleCloseModal = () => {
    setOtpModal(false)
  }

  return (
    <>
      <Modal show={otpModal} onHide={handleCloseModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>OTP Verification</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='container'>
            <Form>
              <Form.Group controlId='formOTP'>
                <Form.Label>Enter OTP</Form.Label>
                <OTPInput
                  value={otp}
                  onChange={handleChange}
                  numInputs={6}
                  renderInput={(props) => <input {...props} className='custome-otp-input' />}
                />
              </Form.Group>
            </Form>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button className='otp-button' onClick={() => handleSubmit(otp)}>
            Verify
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default OTPModal
