import {Formik} from 'formik'
import React from 'react'
import {Link, useNavigate} from 'react-router-dom'
import {API_ROUTES, ROUTES} from '../../Constants'
import {ApiPostNoAuth} from '../../service/api'
import * as Yup from 'yup'
import {toast} from 'react-toastify'

const ForgotPassword = () => {
  const intialData = {
    email: '',
  }

  const Schema = Yup.object().shape({
    email: Yup.string().email('Invalid email').required('Email is required'),
  })

  const navigate = useNavigate()
  return (
    <>
      <main>
        <section className='login-wrapper'>
          <div className='container'>
            <div className='logo'>
              <a href='!#'>
                <img src='/images/logo-new-scaled.jpg' alt='logo' height='auto' width='250' />
              </a>
            </div>
            <div className='login-form-box'>
              <h3 className='mb-lg-5 mb-md-5 mb-3'>Forgot Password</h3>
              <div className='d-flex justify-content-center align-items-center h-50'>
                <Formik
                  initialValues={intialData}
                  validationSchema={Schema}
                  onSubmit={async (values, {setSubmitting}) => {
                    try {
                      const loginData: any = await ApiPostNoAuth(API_ROUTES.ForgotPassword, values)
                      navigate(`/${ROUTES.Login}`)
                      toast.success(loginData?.message)
                      setSubmitting(false)
                    } catch (error: any) {
                      toast.error(error?.error?.message)
                      setSubmitting(false)
                    }
                  }}
                >
                  {({values, handleChange, handleBlur, handleSubmit, isSubmitting}) => (
                    <form onSubmit={handleSubmit}>
                      <div className='row'>
                        <div className='col-12 mt-2'>
                          <div className='form-group'>
                            <label className='d-inline-block'>
                              Email<span className='text-danger'>*</span>
                            </label>
                            <input
                              id='email'
                              type='text'
                              name='email'
                              autoComplete='off'
                              className='form-control'
                              placeholder='Email'
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.email}
                            />
                          </div>
                        </div>

                        <div className='col-12 login-btn-wrapper text-center'>
                          <div className='form-group'>
                            <button
                              type='submit'
                              name='login'
                              value='login'
                              disabled={isSubmitting}
                              className='btn login-btn'
                            >
                              {isSubmitting ? (
                                <div
                                  className='spinner-border text-light'
                                  style={{
                                    height: '20px',
                                    width: '20px',
                                    verticalAlign: 'text-bottom',
                                  }}
                                  role='status'
                                >
                                  <span className='visually-hidden'>Loading...</span>
                                </div>
                              ) : (
                                <> Submit </>
                              )}
                            </button>
                          </div>
                        </div>

                        <div className='col-12 login-btn-wrapper text-center'>
                          <Link to={`/${ROUTES.User}/${ROUTES.Login}`}>Login</Link>
                        </div>
                      </div>
                    </form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </section>
      </main>

      <footer>
        <div className='footer-logo-wrapper'>
          <h5>Powered by</h5>
          <img src='/images/aws.png' alt='aws logo' />
        </div>
      </footer>
    </>
  )
}

export default ForgotPassword
