// Copyright 2020-2021 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: MIT-0

import React, {ChangeEvent, useContext, useState} from 'react'
import {useNavigate} from 'react-router-dom'
import {
  Checkbox,
  DeviceLabels,
  Flex,
  FormField,
  Heading,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
  PrimaryButton,
  Select,
  useMeetingManager,
} from 'amazon-chime-sdk-component-library-react'
import {DefaultBrowserBehavior, MeetingSessionConfiguration} from 'amazon-chime-sdk-js'

import {getErrorContext} from '../../providers/ErrorProvider'
import Card from '../../components/Card'
import Spinner from '../../components/icons/Spinner'
import DevicePermissionPrompt from '../DevicePermissionPrompt'
import RegionSelection from './RegionSelection'
import {useAppState} from '../../providers/AppStateProvider'
import {MeetingManagerJoinOptions} from 'amazon-chime-sdk-component-library-react/lib/providers/MeetingProvider/types'
import {MeetingMode, VideoFiltersCpuUtilization} from '../../../../Types/chimeTypes'
import {createGetAttendeeCallback, createMeetingAndAttendee} from '../../../../Utils/api'
import meetingConfig from '../../../../config/meetingConfig'
import {routes} from '../../../../Constants/routes'

const VIDEO_TRANSFORM_FILTER_OPTIONS = [
  {value: VideoFiltersCpuUtilization.Disabled, label: 'Disable Video Filter'},
  {
    value: VideoFiltersCpuUtilization.CPU10Percent,
    label: 'Video Filter CPU 10%',
  },
  {
    value: VideoFiltersCpuUtilization.CPU20Percent,
    label: 'Video Filter CPU 20%',
  },
  {
    value: VideoFiltersCpuUtilization.CPU40Percent,
    label: 'Video Filter CPU 40%',
  },
]

const MeetingForm: React.FC = () => {
  const meetingManager = useMeetingManager()
  const {
    region,
    meetingId,
    localUserName,
    meetingMode,
    enableSimulcast,
    priorityBasedPolicy,
    keepLastFrameWhenPaused,
    isWebAudioEnabled,
    videoTransformCpuUtilization: videoTransformCpuUtilization,
    setJoinInfo,
    isEchoReductionEnabled,
    toggleEchoReduction,
    toggleWebAudio,
    toggleSimulcast,
    togglePriorityBasedPolicy,
    toggleKeepLastFrameWhenPaused,
    setMeetingMode,
    setMeetingId,
    setLocalUserName,
    setRegion,
    setCpuUtilization,
    skipDeviceSelection,
    toggleMeetingJoinDeviceSelection,
  } = useAppState()
  const [meetingErr, setMeetingErr] = useState(false)
  const [nameErr, setNameErr] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const {errorMessage, updateErrorMessage} = useContext(getErrorContext())
  const navigate = useNavigate()
  const browserBehavior = new DefaultBrowserBehavior()

  const handleJoinMeeting = async (e: React.FormEvent) => {
    e.preventDefault()
    const id = meetingId.trim().toLocaleLowerCase()
    const attendeeName = localUserName.trim()

    if (!attendeeName) {
      if (!attendeeName) {
        setNameErr(true)
      }

      return
    }

    setIsLoading(true)
    meetingManager.getAttendee = createGetAttendeeCallback(id)

    try {
      const JoinInfo = await createMeetingAndAttendee(
        id,
        attendeeName,
        region,
        isEchoReductionEnabled
      )
      console.log('JoinInfoJoinInfo', JoinInfo)
      //   Meeting: {
      //     ExternalMeetingId: "46062176-86e3-4799-a8ea-f012482d1c64",
      //     MediaPlacement: {
      //       AudioFallbackUrl:
      //         "wss://haxrp.m1.ue1.app.chime.aws:443/calls/fe51a67d-6797-45f7-8ac4-b2530e852713",
      //       AudioHostUrl:
      //         "be601ca8c9cabfc31cfca5ce26d74985.k.m1.ue1.app.chime.aws:3478",
      //       EventIngestionUrl:
      //         "https://data.svc.ue1.ingest.chime.aws/v1/client-events",
      //       ScreenDataUrl:
      //         "wss://bitpw.m1.ue1.app.chime.aws:443/v2/screen/fe51a67d-6797-45f7-8ac4-b2530e852713",
      //       ScreenSharingUrl:
      //         "wss://bitpw.m1.ue1.app.chime.aws:443/v2/screen/fe51a67d-6797-45f7-8ac4-b2530e852713",
      //       ScreenViewingUrl:
      //         "wss://bitpw.m1.ue1.app.chime.aws:443/ws/connect?passcode=null&viewer_uuid=null&X-BitHub-Call-Id=fe51a67d-6797-45f7-8ac4-b2530e852713",
      //       SignalingUrl:
      //         "wss://signal.m1.ue1.app.chime.aws/control/fe51a67d-6797-45f7-8ac4-b2530e852713",
      //       TurnControlUrl:
      //         "https://2713.cell.us-east-1.meetings.chime.aws/v2/turn_sessions",
      //     },
      //     MediaRegion: "us-east-1",
      //     MeetingArn:
      //       "arn:aws:chime:us-east-1:604906689878:meeting/fe51a67d-6797-45f7-8ac4-b2530e852713",
      //     MeetingFeatures: {
      //       Audio: {
      //         EchoReduction: "AVAILABLE",
      //       },
      //     },
      //     MeetingId: "fe51a67d-6797-45f7-8ac4-b2530e852713",
      //     TenantIds: [],
      //   },
      //   Attendee: {
      //     AttendeeId: "e41a95a0-cccd-5255-1096-5cea1fbe5fcc",
      //     Capabilities: {
      //       Audio: "SendReceive",
      //       Content: "SendReceive",
      //       Video: "SendReceive",
      //     },
      //     ExternalUserId: "ABCD_2f8dc53e-8a66-43e9-9aa6-e38c00c1d871",
      //     JoinToken:
      //       "ZTQxYTk1YTAtY2NjZC01MjU1LTEwOTYtNWNlYTFmYmU1ZmNjOjRiYmMzMmI1LThjMzEtNDFhNy1hYTE0LTJkNmFjNjQzOWJkMg",
      //   },
      // };
      setJoinInfo(JoinInfo as any)

      const meetingSessionConfiguration = new MeetingSessionConfiguration(
        JoinInfo?.Meeting,
        JoinInfo?.Attendee
      )
      if (
        meetingConfig.postLogger &&
        meetingSessionConfiguration.meetingId &&
        meetingSessionConfiguration.credentials &&
        meetingSessionConfiguration.credentials.attendeeId
      ) {
        const existingMetadata = meetingConfig.postLogger.metadata
        meetingConfig.postLogger.metadata = {
          ...existingMetadata,
          meetingId: meetingSessionConfiguration.meetingId,
          attendeeId: meetingSessionConfiguration.credentials.attendeeId,
        }
      }

      setRegion(JoinInfo.Meeting.MediaRegion)
      meetingSessionConfiguration.enableSimulcastForUnifiedPlanChromiumBasedBrowsers =
        enableSimulcast
      if (priorityBasedPolicy) {
        meetingSessionConfiguration.videoDownlinkBandwidthPolicy = priorityBasedPolicy
      }
      meetingSessionConfiguration.keepLastFrameWhenPaused = keepLastFrameWhenPaused
      const options: MeetingManagerJoinOptions = {
        deviceLabels:
          meetingMode === MeetingMode.Spectator ? DeviceLabels.None : DeviceLabels.AudioAndVideo,
        enableWebAudio: isWebAudioEnabled,
        skipDeviceSelection,
      }

      await meetingManager.join(meetingSessionConfiguration, options)
      if (meetingMode === MeetingMode.Spectator) {
        await meetingManager.start()
        navigate(`${routes.MEETING}/${meetingId || JoinInfo?.Meeting?.MeetingId}`)
      } else {
        setMeetingMode(MeetingMode.Attendee)
        setMeetingId(meetingId || JoinInfo?.Meeting?.MeetingId)
        navigate(routes.DEVICE)
      }
    } catch (error) {
      updateErrorMessage((error as Error).message)
    }
  }

  const closeError = (): void => {
    updateErrorMessage('')
    setMeetingId('')
    setLocalUserName('')
    setIsLoading(false)
  }

  return (
    <form>
      <Heading tag='h1' level={4} css='margin-bottom: 1rem'>
        Join a meeting
      </Heading>
      <FormField
        field={Input}
        label='Meeting Id'
        value={meetingId}
        infoText='Anyone with access to the meeting ID can join'
        fieldProps={{
          name: 'meetingId',
          placeholder: 'Enter Meeting Id',
        }}
        errorText='Please enter a valid meeting ID'
        error={meetingErr}
        onChange={(e: ChangeEvent<HTMLInputElement>): void => {
          setMeetingId(e.target.value)
          if (meetingErr) {
            setMeetingErr(false)
          }
        }}
      />
      <FormField
        field={Input}
        label='Name'
        value={localUserName}
        fieldProps={{
          name: 'name',
          placeholder: 'Enter Your Name',
        }}
        errorText='Please enter a valid name'
        error={nameErr}
        onChange={(e: ChangeEvent<HTMLInputElement>): void => {
          setLocalUserName(e.target.value)
          if (nameErr) {
            setNameErr(false)
          }
        }}
      />
      <RegionSelection setRegion={setRegion} region={region} />
      <FormField
        field={Checkbox}
        label='Join w/o Audio and Video (spectator mode)'
        value=''
        checked={meetingMode === MeetingMode.Spectator}
        onChange={(): void => {
          if (meetingMode === MeetingMode.Spectator) {
            setMeetingMode(MeetingMode.Attendee)
          } else {
            setMeetingMode(MeetingMode.Spectator)
          }
        }}
      />
      <FormField
        field={Checkbox}
        label='Enable Web Audio'
        value=''
        checked={isWebAudioEnabled}
        onChange={toggleWebAudio}
        infoText='Enable Web Audio to use Voice Focus'
      />
      {/* Amazon Chime Echo Reduction is a premium feature, please refer to the Pricing page for details.*/}
      {isWebAudioEnabled && (
        <FormField
          field={Checkbox}
          label='Enable Echo Reduction'
          value=''
          checked={isEchoReductionEnabled}
          onChange={toggleEchoReduction}
          infoText='Enable Echo Reduction (new meetings only)'
        />
      )}
      {/* BlurSelection */}
      {/* Background Video Transform Selections */}
      <FormField
        field={Select}
        options={VIDEO_TRANSFORM_FILTER_OPTIONS}
        onChange={(e: ChangeEvent<HTMLSelectElement>): void => {
          setCpuUtilization(e.target.value)
        }}
        value={videoTransformCpuUtilization}
        label='Background Filters CPU Utilization'
      />
      {/* Video uplink and downlink policies */}
      {browserBehavior.isSimulcastSupported() && (
        <FormField
          field={Checkbox}
          label='Enable Simulcast'
          value=''
          checked={enableSimulcast}
          onChange={toggleSimulcast}
        />
      )}

      {browserBehavior.supportDownlinkBandwidthEstimation() && (
        <FormField
          field={Checkbox}
          label='Use Priority-Based Downlink Policy'
          value=''
          checked={priorityBasedPolicy !== undefined}
          onChange={togglePriorityBasedPolicy}
        />
      )}
      <FormField
        field={Checkbox}
        label='Keep Last Frame When Paused'
        value=''
        checked={keepLastFrameWhenPaused}
        onChange={toggleKeepLastFrameWhenPaused}
      />
      <FormField
        field={Checkbox}
        label='Skip meeting join device selection'
        value=''
        checked={skipDeviceSelection}
        onChange={toggleMeetingJoinDeviceSelection}
        infoText='Please select the devices manually to successfully join a meeting'
      />
      <Flex container layout='fill-space-centered' style={{marginTop: '2.5rem'}}>
        {isLoading ? <Spinner /> : <PrimaryButton label='Continue' onClick={handleJoinMeeting} />}
      </Flex>
      {errorMessage && (
        <Modal size='md' onClose={closeError}>
          <ModalHeader title={`Meeting ID: ${meetingId}`} />
          <ModalBody>
            <Card
              title='Unable to join meeting'
              description='There was an issue finding that meeting. The meeting may have already ended, or your authorization may have expired.'
              smallText={errorMessage}
            />
          </ModalBody>
        </Modal>
      )}
      <DevicePermissionPrompt />
    </form>
  )
}

export default MeetingForm
