// Copyright 2020-2021 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: MIT-0

import React from 'react'
import {Heading} from 'amazon-chime-sdk-component-library-react'
// import MeetingJoinDetails from "../../containers/MeetingJoinDetails";
import {StyledLayout} from './Styled'
import DeviceSelection from '../../components/DeviceSelection'
import MeetingJoinDetails from '../../container/MeetingJoinDetails'

const DeviceSetup: React.FC = () => (
  <StyledLayout>
    <Heading tag='h1' level={3} css='align-self: flex-start'>
      Device settings
    </Heading>
    <DeviceSelection />
    <MeetingJoinDetails />
  </StyledLayout>
)

export default DeviceSetup
