import React from 'react'

const AdminDashboard = () => {
  return (
    <div id='content' className='p-3 pt-1'>
      <h2 className='mb-4'>Dashboard Content</h2>
      <p>Your additional content goes here</p>
    </div>
  )
}

export default AdminDashboard
