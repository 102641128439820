import React, {useEffect, useState} from 'react'
import {useNavigate, useParams} from 'react-router-dom'
import ReactQuill from 'react-quill'
import {ErrorMessage, Formik} from 'formik'
import {toast} from 'react-toastify'
import * as Yup from 'yup'
import {ApiGet, ApiPost, ApiPut} from '../../../service/api'
import {API_ROUTES, ROUTES} from '../../../Constants'
import Button from '../../../Components/Button'

const ModifyPages = () => {
  const {id} = useParams()
  const intialData = {
    name: '',
    title: '',
    keywords: '',
    description: '',
    pageUrl: '',
    longDescription: '',
    order: 0,
    status: '',
  }
  const navigate = useNavigate()
  const [formikData, setFormikData] = useState(intialData)

  const Schema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    title: Yup.string().required('Title is required'),
    keywords: Yup.string().required('Keywords is required'),
    description: Yup.string().required('Description is required'),
    pageUrl: Yup.string().required('Page URL is required'),
    longDescription: Yup.string().required('Long Description is required'),
    order: Yup.number().required('Order is required').min(1, 'Order must be greater than to 0'),
    status: Yup.string().required('Status is required'),
  })

  const getData = async () => {
    try {
      const response: any = await ApiGet(`${API_ROUTES.DynamicPage}/${id}`)
      setFormikData({
        name: response?.data?.name,
        title: response?.data?.title,
        keywords: response?.data?.keywords,
        description: response?.data?.description,
        pageUrl: response?.data?.pageUrl,
        longDescription: response?.data?.longDescription,
        order: response?.data?.order,
        status: response?.data?.status,
      })
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (id) getData()
  }, [])

  return (
    <div id='content' className='p-3 pt-1'>
      <div className='container '>
        <div className='input-group mb-3 justify-content-end'>
          <a href='#' className='btn btn-primary'>
            Back
          </a>
        </div>
        <div className='card'>
          <div className='card-header'>
            <h2 className='mb-0'>{id ? 'Edit' : 'Add'} Page</h2>
          </div>
          <div className='card-body'>
            <Formik
              initialValues={formikData}
              enableReinitialize
              validationSchema={Schema}
              onSubmit={async (values, {setSubmitting}) => {
                try {
                  if (id) {
                    const responseData: any = await ApiPut(
                      `${API_ROUTES.DynamicPage}/${id}`,
                      values
                    )
                    toast.success(responseData?.message)
                  } else {
                    const responseData: any = await ApiPost(API_ROUTES.DynamicPage, values)
                    toast.success(responseData?.message)
                  }
                  navigate(`/${ROUTES.Admin}/${ROUTES.AdminPages}`)
                  setSubmitting(false)
                } catch (error: any) {
                  toast.error(error?.error?.message)
                  setSubmitting(false)
                }
              }}
            >
              {({values, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue}) => (
                <form onSubmit={handleSubmit}>
                  <div className='form-group'>
                    <label htmlFor='name'>Name:</label>
                    <input
                      type='text'
                      className='form-control elegant-input'
                      id='name'
                      name='name'
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.name}
                    />
                    <ErrorMessage name='name' component='div' className='red-error' />
                  </div>
                  <div className='form-group'>
                    <label htmlFor='title'>Title:</label>
                    <input
                      type='text'
                      className='form-control elegant-input'
                      id='title'
                      name='title'
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.title}
                    />
                    <ErrorMessage name='title' component='div' className='red-error' />
                  </div>
                  <div className='form-group'>
                    <label htmlFor='keywords'>Keywords:</label>
                    <input
                      type='text'
                      className='form-control elegant-input'
                      id='keywords'
                      name='keywords'
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.keywords}
                    />
                    <ErrorMessage name='keywords' component='div' className='red-error' />
                  </div>
                  <div className='form-group'>
                    <label htmlFor='description'>Description:</label>
                    <textarea
                      className='form-control elegant-textarea elegant-input'
                      id='description'
                      name='description'
                      rows={2}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.description}
                    />
                    <ErrorMessage name='description' component='div' className='red-error' />
                  </div>
                  <div className='form-group'>
                    <label htmlFor='order'>Order:</label>
                    <input
                      type='number'
                      className='form-control elegant-input'
                      id='order'
                      name='order'
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.order}
                    />
                    <ErrorMessage name='order' component='div' className='red-error' />
                  </div>
                  <div className='form-group'>
                    <label htmlFor='pageUrl'>Page URL:</label>
                    <input
                      type='text'
                      className='form-control elegant-input'
                      id='pageUrl'
                      name='pageUrl'
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.pageUrl}
                    />
                    <ErrorMessage name='pageUrl' component='div' className='red-error' />
                  </div>
                  <div className='form-group'>
                    <label htmlFor='longDescription'>Long Description:</label>
                    <ReactQuill
                      theme='snow'
                      onChange={(value) => setFieldValue('longDescription', value)}
                    />
                    <ErrorMessage name='longDescription' component='div' className='red-error' />
                  </div>

                  <div className='form-group'>
                    <label htmlFor='status'>Status:</label>
                    <select
                      className='form-control elegant-input'
                      name='status'
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.status}
                    >
                      <option value=''>Select Status</option>
                      <option value='Published'>Published</option>
                      <option value='Draft'>Draft</option>
                    </select>
                    <ErrorMessage name='status' component='div' className='red-error' />
                  </div>

                  <Button isSubmitting={isSubmitting} text={id ? 'Update' : 'Create'} />
                </form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ModifyPages
