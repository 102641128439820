import React, {useEffect, useState} from 'react'
import Button from '../../Components/Button'
import {ErrorMessage, Formik} from 'formik'
import {API_ROUTES} from '../../Constants'
import {toast} from 'react-toastify'
import * as Yup from 'yup'
import {ApiPut} from '../../service/api'
import {getSettingData} from '../../common/api'
import {UserSettings} from '../../Types'

const SMSNotifications = () => {
  const intialData = {
    countryCode: '',
    mobileNumber: '',
    smsNotification: false,
  }
  const [formikData, setFormikData] = useState(intialData)
  const abortController = new AbortController()

  const Schema = Yup.object().shape({
    countryCode: Yup.string().required('Country Code is required'),
    mobileNumber: Yup.string()
      .matches(/^\d+$/, 'Mobileno must contain numbers only')
      .required('Mobileno is required'),
  })

  const getData = async () => {
    const response: UserSettings = await getSettingData(abortController)
    setFormikData({
      countryCode: response?.pinLessEntryAndSmsNotification?.countryCode ?? '',
      mobileNumber: response?.pinLessEntryAndSmsNotification?.mobileNumber ?? '',
      smsNotification: response?.pinLessEntryAndSmsNotification?.smsNotification ?? '',
    })
  }

  useEffect(() => {
    getData()

    return () => {
      abortController.abort()
    }
  }, [])

  return (
    <main>
      <section className='settings'>
        <div className='container-fluid px-0'>
          <div className='settings-wrapper'>
            <div className='settings-content' role='presentation'>
              <div className='bar' role='presentation'>
                <button type='button' className='btn button-light-primary'>
                  <span className='fa fa-chevron-left me-2' aria-hidden='true'></span>All settings
                </button>
              </div>
              <div className='panel panel--settings' aria-labelledby='contact_details_heading'>
                <div className='container' role='presentation'>
                  <h2 className='panel__header'>PIN-less entry & SMS notifications</h2>
                  <p role='note'>
                    PIN-less entry allows you to enter or create a meeting or without entering your
                    PIN when you meeting from the phone number below.
                  </p>
                  <p>
                    There are two types of SMS notifications. Firstly, you will receive SMS
                    reminders for scheduled meetings 15 minutes prior to your meeting. Secondly, you
                    will receive an SMS notification when someone joins your meeting before you
                    arrive.
                  </p>
                  <p className='mb-0'>
                    <b>Please note:</b>
                  </p>
                  <p>
                    Main office lines are not recommended for PIN-less entry to prevent calling
                    conflicts with other users in the same office.
                  </p>
                  <h4 className='mt-3 fw-normal'>Curated music playlist</h4>
                  <Formik
                    initialValues={formikData}
                    enableReinitialize
                    validationSchema={Schema}
                    onSubmit={async (values, {setSubmitting}) => {
                      try {
                        const responseData: any = await ApiPut(API_ROUTES.UserSettingsChange, {
                          pinLessEntryAndSmsNotification: values,
                        })

                        toast.success(responseData?.message)
                        setSubmitting(false)
                      } catch (error: any) {
                        toast.error(error?.error?.message)
                        setSubmitting(false)
                      }
                    }}
                  >
                    {({
                      values,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                      setFieldValue,
                    }) => (
                      <form onSubmit={handleSubmit}>
                        <div className='row'>
                          <div className='col-md-6'>
                            <div className='form-group'>
                              <select
                                id='countryCode'
                                className='form-select valid'
                                name='countryCode'
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.countryCode}
                                style={{
                                  borderRadius: '20px',
                                }}
                                aria-invalid='false'
                              >
                                <option value=''>Country</option>
                                <option value='CA'>Canada (+1)</option>
                                <option value='US'>United States (+1)</option>
                                <option value='AU'>Australia (+61)</option>
                                <option value='AT'>Austria (+43)</option>
                                <option value='BE'>Belgium (+32)</option>
                                <option value='DE'>Germany (+49)</option>
                                <option value='KR'>South Korea (+82)</option>
                                <option value='NL'>Netherlands (+31)</option>
                                <option value='GB'>United Kingdom (+44)</option>
                                <option value='HK'>Hong Kong (+852)</option>
                                <option value='CN'>China (+86)</option>
                                <option value='FR'>France (+33)</option>
                                <option value='IL'>Israel (+972)</option>
                                <option value='SG'>Singapore (+65)</option>
                                <option value='ZA'>South Africa (+27)</option>
                                <option value='CH'>Switzerland (+41)</option>
                                <option value='GR'>Greece (+30)</option>
                                <option value='MY'>Malaysia (+60)</option>
                                <option value='IN'>India (+91)</option>
                              </select>
                              <ErrorMessage
                                name='countryCode'
                                component='div'
                                className='red-error'
                              />
                            </div>
                          </div>
                          <div className='col-md-6'>
                            <div className='form-group'>
                              <input
                                id='mobileNumber'
                                type='text'
                                name='mobileNumber'
                                placeholder='Enter Your Phone Number'
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.mobileNumber}
                              />
                              <ErrorMessage
                                name='mobileNumber'
                                component='div'
                                className='red-error'
                              />
                            </div>
                          </div>
                        </div>
                        <div className='form-group two-factor__grey'>
                          <span>SMS notifications</span>
                          <div className='form-check form-switch form-switch-lg'>
                            <input
                              className='form-check-input'
                              type='checkbox'
                              id='smsNotification'
                              onChange={(e) => {
                                setFieldValue('smsNotification', e.target.checked)
                              }}
                              checked={values.smsNotification}
                            />
                            <label className='form-check-label' htmlFor='smsNotification'></label>
                          </div>
                        </div>

                        <Button isSubmitting={isSubmitting} />
                      </form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  )
}

export default SMSNotifications
