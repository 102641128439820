import React, {useEffect, useState} from 'react'
import {useNavigate, useParams} from 'react-router-dom'
import {ErrorMessage, Formik} from 'formik'
import {toast} from 'react-toastify'
import * as Yup from 'yup'
import ReactQuill from 'react-quill'
import Button from '../../../Components/Button'
import {ApiGet, ApiPost, ApiPut} from '../../../service/api'
import {API_ROUTES, ROUTES} from '../../../Constants'

const ModifyEmails = () => {
  const {id} = useParams()
  const intialData = {
    type: '',
    content: '',
    subject: '',
    status: 'active',
  }
  const navigate = useNavigate()
  const [formikData, setFormikData] = useState(intialData)

  const Schema = Yup.object().shape({
    type: Yup.string().required('Template Type is required'),
    subject: Yup.string().required('Subject Type is required'),
    content: Yup.string().required('Content Type is required'),
  })

  const getData = async () => {
    try {
      const response: any = await ApiGet(`${API_ROUTES.EmailTemplate}/${id}`)
      setFormikData({
        type: response?.data?.type,
        content: response?.data?.content,
        subject: response?.data?.subject,
        status: response?.data?.status,
      })
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (id) getData()
  }, [])

  return (
    <div id='content' className='p-3 pt-1'>
      <div className='container '>
        <div className='input-group mb-3 justify-content-end'>
          <a href='#' className='btn btn-primary'>
            Back
          </a>
        </div>
        <div className='card'>
          <div className='card-header'>
            <h2 className='mb-0'>{id ? 'Edit' : 'Add'} Email Template</h2>
          </div>
          <div className='card-body'>
            <Formik
              initialValues={formikData}
              enableReinitialize
              validationSchema={Schema}
              onSubmit={async (values, {setSubmitting}) => {
                try {
                  if (id) {
                    const responseData: any = await ApiPut(
                      `${API_ROUTES.EmailTemplate}/${id}`,
                      values
                    )
                    toast.success(responseData?.message)
                  } else {
                    const responseData: any = await ApiPost(API_ROUTES.EmailTemplate, values)
                    toast.success(responseData?.message)
                  }
                  navigate(`/${ROUTES.Admin}/${ROUTES.AdminEmails}`)
                  setSubmitting(false)
                } catch (error: any) {
                  toast.error(error?.error?.message)
                  setSubmitting(false)
                }
              }}
            >
              {({values, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue}) => (
                <form onSubmit={handleSubmit}>
                  <div className='form-group'>
                    <label htmlFor='type'>Template Type:</label>
                    <select
                      className='form-control elegant-input'
                      name='type'
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.type}
                    >
                      <option value=''>Select Type</option>
                      <option value='Welcome_Email'>Welcome Email</option>
                      <option value='Password_Reset'>Password Reset</option>
                      <option value='Trial_Period_Expire'>Trial Period Expire</option>
                    </select>
                    <ErrorMessage name='type' component='div' className='red-error' />
                  </div>

                  <div className='form-group'>
                    <label htmlFor='trial_days'>Email Subject:</label>
                    <input
                      type='text'
                      className='form-control elegant-input'
                      id='subject'
                      name='subject'
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.subject}
                    />
                    <ErrorMessage name='subject' component='div' className='red-error' />
                  </div>
                  <div className='form-group'>
                    <label htmlFor='trial_days'>Email Content:</label>
                    <ReactQuill
                      theme='snow'
                      value={values.content}
                      onChange={(value) => setFieldValue('content', value)}
                    />
                    <ErrorMessage name='content' component='div' className='red-error' />
                  </div>

                  <Button isSubmitting={isSubmitting} text={id ? 'Update' : 'Create'} />
                </form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ModifyEmails
