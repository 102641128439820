import React, {useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import {API_ROUTES, ROUTES} from '../../../Constants'
import {ApiDelete, ApiGet} from '../../../service/api'
import {useDebounce} from '../../../Hooks/useDebounce'
import ReactPaginateComponent from '../../../Components/ReactPaginate'
import {SubscriptionPlan} from '../../../Types'

const AdminPlans = () => {
  const [query, setQuery] = useState({
    page: 1,
    limit: 10,
    filter: '',
    totalDocs: 0,
    totalPages: 0,
  })

  const [data, setData] = useState([])

  const debouncedFilter = useDebounce(query.filter, 500)

  const updateQuery = (updates: any) => {
    setQuery((prev) => ({...prev, ...updates}))
  }

  const getData = async (page: number, limit: number, filter: string) => {
    try {
      const response: any = await ApiGet(API_ROUTES.Plan, {
        page,
        limit,
        filter,
      })

      setData(response?.data?.docs)

      updateQuery({totalDocs: response?.data?.totalDocs, totalPages: response?.data?.totalPages})
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    const {page, limit, filter} = query
    getData(page, limit, filter)
  }, [debouncedFilter, query.page, query.limit])

  const handleDelete = async (id: string = '') => {
    try {
      await ApiDelete(`${API_ROUTES.Plan}/${id}`).then(() => {
        const {page, limit, filter} = query
        getData(1, limit, filter)
      })
    } catch (error) {
      console.error(error)
    }
  }

  // Invoke when user click to request another page.
  const handlePageClick = (event: any) => {
    updateQuery({page: event.selected + 1})
  }

  return (
    <div id='content' className='p-3 pt-1'>
      <h2 className='mb-4'>Plan List</h2>
      <div className='mb-3'>
        <Link
          to={`/${ROUTES.Admin}/${ROUTES.AdminPlans}/${ROUTES.Create}`}
          className='btn btn-success'
        >
          Add
        </Link>
      </div>
      <div className='table-responsive'>
        <table className='table table-bordered table-hover'>
          <thead className='thead-dark'>
            <tr>
              <th>SL NO</th>
              <th>Name</th>
              <th>Price</th>
              <th>Description</th>
              <th>IS Active</th>
              <th className='text-center'>Action</th>
            </tr>
          </thead>
          <tbody>
            {data?.map((plan: SubscriptionPlan, index: number) => (
              <tr key={plan?._id}>
                <td>{index + 1 + (query.page - 1) * query.limit}</td>
                <td>{plan?.planName}</td>
                <td>{plan?.price}</td>
                <td>{plan?.description}</td>
                <td>
                  <div className='btn btn-success'>{plan?.status}</div>
                </td>
                <td>
                  <Link
                    to={`/${ROUTES.Admin}/${ROUTES.AdminPlans}/${ROUTES.Edit}/${plan?._id}`}
                    className='btn btn-primary btn-sm me-2'
                  >
                    <i className='fa fa-edit'></i> Edit
                  </Link>
                  <button
                    onClick={() => handleDelete(plan?._id)}
                    className='btn btn-danger btn-sm'
                    title='Delete'
                  >
                    <i className='fa fa-trash'></i> Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <ReactPaginateComponent pageCount={query?.totalPages} handlePageClick={handlePageClick} />
      </div>
    </div>
  )
}

export default AdminPlans
