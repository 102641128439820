import {useEffect} from 'react'
import {useUser} from './useUser'
import {useLocalStorage} from './useLocalStorage'
import {User} from '../Types'
import {STORAGE_KEY} from '../Constants'

export const useAuth = () => {
  // we can re export the user methods or object from this hook
  const {user, addUser, removeUser, setUser} = useUser()
  const {getItem} = useLocalStorage()

  useEffect(() => {
    /* eslint-disable */
    const user = getItem(STORAGE_KEY.User)
    if (user) {
      addUser(JSON.parse(user))
    }
  }, [])

  const login = (user: User | null) => {
    addUser(user)
  }

  const logout = () => {
    removeUser()
  }
  /* eslint-enable */

  return {user, login, logout, setUser}
}
