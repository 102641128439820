import React, {useEffect, useState} from 'react'
import {ErrorMessage, Formik} from 'formik'
import * as Yup from 'yup'
import {ApiGet, ApiPost} from '../../service/api'
import {API_ROUTES} from '../../Constants'
import {ServiceConfigurations} from '../../Types'
import Button from '../../Components/Button'
import {toast} from 'react-toastify'

const AdminSettings = () => {
  const intialData = {
    awsSesAccessKey: '',
    awsSesSecretKey: '',
    awsSesRegion: '',
    awsSesSourceEmail: '',
    bwUsername: '',
    bwPassword: '',
    bwMessageApplicationId: '',
    bwAccountId: '',
    bwFromNumber: '',
  }
  const [formikData, setFormikData] = useState(intialData)

  const getData = async () => {
    try {
      const response: any = await ApiGet(API_ROUTES.GlobalSetting)
      const configurations: ServiceConfigurations = response?.data
      console.log('configurations', configurations)
      setFormikData({
        awsSesAccessKey: configurations?.awsSesAccessKey,
        awsSesSecretKey: configurations?.awsSesSecretKey,
        awsSesRegion: configurations?.awsSesRegion,
        awsSesSourceEmail: configurations?.awsSesSourceEmail,
        bwUsername: configurations?.bwUsername,
        bwPassword: configurations?.bwPassword,
        bwMessageApplicationId: configurations?.bwMessageApplicationId,
        bwAccountId: configurations?.bwAccountId,
        bwFromNumber: configurations?.bwFromNumber,
      })
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getData()
  }, [])

  return (
    <div id='content' className='p-3 pt-1'>
      <div className='container '>
        <div className='input-group mb-3 justify-content-end'>
          <a href='#' className='btn btn-primary'>
            Back
          </a>
        </div>
        <div className='card'>
          <div className='card-header'>
            <h2 className='mb-0'>Admin Settings </h2>
          </div>
          <div className='card-body'>
            <Formik
              initialValues={formikData}
              enableReinitialize
              // validationSchema={Schema}
              onSubmit={async (values, {setSubmitting}) => {
                try {
                  const responseData: any = await ApiPost(API_ROUTES.GlobalSetting, values)

                  toast.success(responseData?.message)
                  setSubmitting(false)
                } catch (error: any) {
                  toast.error(error?.error?.message)
                  setSubmitting(false)
                }
              }}
            >
              {({values, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue}) => (
                <form onSubmit={handleSubmit}>
                  <div className='form-group'>
                    <label htmlFor='name'>AWS Ses AccessKey:</label>
                    <input
                      type='text'
                      className='form-control elegant-input'
                      id='awsSesAccessKey'
                      name='awsSesAccessKey'
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.awsSesAccessKey}
                    />
                  </div>

                  <div className='form-group'>
                    <label htmlFor='price'>AWS Ses SecretKey:</label>
                    <input
                      type='text'
                      className='form-control elegant-input'
                      id='awsSesSecretKey'
                      name='awsSesSecretKey'
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.awsSesSecretKey}
                    />
                  </div>

                  <div className='form-group'>
                    <label htmlFor='price'>AWSSes Region:</label>
                    <input
                      type='text'
                      className='form-control elegant-input'
                      id='awsSesRegion'
                      name='awsSesRegion'
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.awsSesRegion}
                    />
                  </div>

                  <div className='form-group'>
                    <label htmlFor='price'>AWS Ses SourceEmail:</label>
                    <input
                      type='text'
                      className='form-control elegant-input'
                      id='awsSesSourceEmail'
                      name='awsSesSourceEmail'
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.awsSesSourceEmail}
                    />
                  </div>
                  <div className='form-group'>
                    <label htmlFor='price'>Bandwidth Username:</label>
                    <input
                      type='text'
                      className='form-control elegant-input'
                      id='bwUsername'
                      name='bwUsername'
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.bwUsername}
                    />
                  </div>
                  <div className='form-group'>
                    <label htmlFor='price'>Bandwidth Password:</label>
                    <input
                      type='password'
                      className='form-control elegant-input'
                      id='bwPassword'
                      name='bwPassword'
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.bwPassword}
                    />
                  </div>
                  <div className='form-group'>
                    <label htmlFor='price'>Bandwidth Message ApplicationId:</label>
                    <input
                      type='text'
                      className='form-control elegant-input'
                      id='bwMessageApplicationId'
                      name='bwMessageApplicationId'
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.bwMessageApplicationId}
                    />
                  </div>
                  <div className='form-group'>
                    <label htmlFor='price'>Bandwidth AccountId:</label>
                    <input
                      type='text'
                      className='form-control elegant-input'
                      id='bwAccountId'
                      name='bwAccountId'
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.bwAccountId}
                    />
                  </div>
                  <div className='form-group'>
                    <label htmlFor='price'>Bandwidth FromNumber:</label>
                    <input
                      type='text'
                      className='form-control elegant-input'
                      id='bwFromNumber'
                      name='bwFromNumber'
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.bwFromNumber}
                    />
                  </div>

                  <Button isSubmitting={isSubmitting} text='Update' />
                </form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AdminSettings
