import React, {useEffect, useState} from 'react'
import {UserSettings} from '../../Types'
import {getSettingData} from '../../common/api'
import {Formik} from 'formik'
import {API_ROUTES} from '../../Constants'
import {toast} from 'react-toastify'
import {ApiPut} from '../../service/api'
import Button from '../../Components/Button'

const WaitingRoom = () => {
  const intialData = {
    waitingRoomFlag: false,
  }
  const [formikData, setFormikData] = useState(intialData)
  const abortController = new AbortController()

  const getData = async () => {
    const response: UserSettings = await getSettingData(abortController)
    setFormikData({
      waitingRoomFlag: response?.waitingRoomFlag ?? false,
    })
  }

  useEffect(() => {
    getData()

    return () => {
      abortController.abort()
    }
  }, [])
  return (
    <main>
      <section className='settings'>
        <div className='container-fluid px-0'>
          <div className='settings-wrapper'>
            <div className='settings-content' role='presentation'>
              <div className='bar' role='presentation'>
                <button type='button' className='btn button-light-primary'>
                  <span className='fa fa-chevron-left me-2' aria-hidden='true'></span>All settings
                </button>
              </div>
              <div className='panel panel--settings' aria-labelledby='contact_details_heading'>
                <div className='container' role='presentation'>
                  <h2 className='panel__header'>Waiting room</h2>
                  <p role='note'>
                    With the waiting room enabled, your meeting will not begin until a moderator
                    joins. To join as moderator via web, ensure you are logged into your account. To
                    join as moderator via phone, use your moderator PIN.
                  </p>
                  <p className='mb-0'>
                    <b>Participants who connect via web</b> will need to be admitted or denied entry
                    by a moderator.
                  </p>
                  <p>
                    <b>Participants who connect via dial-in number </b>will hear hold music until a
                    moderator joins.
                  </p>
                  <Formik
                    initialValues={formikData}
                    enableReinitialize
                    onSubmit={async (values, {setSubmitting}) => {
                      try {
                        const responseData: any = await ApiPut(
                          API_ROUTES.UserSettingsChange,
                          values
                        )

                        toast.success(responseData?.message)
                        setSubmitting(false)
                      } catch (error: any) {
                        toast.error(error?.error?.message)
                        setSubmitting(false)
                      }
                    }}
                  >
                    {({values, setFieldValue, handleSubmit, isSubmitting}) => (
                      <form onSubmit={handleSubmit}>
                        <div className='form-group two-factor__grey'>
                          <span>Waiting room</span>
                          <div className='form-check form-switch form-switch-lg'>
                            <input
                              className='form-check-input'
                              type='checkbox'
                              id='waitingRoomFlag'
                              onChange={(e) => {
                                setFieldValue('waitingRoomFlag', e.target.checked)
                              }}
                              checked={values.waitingRoomFlag}
                            />
                            <label className='form-check-label' htmlFor='waitingRoomFlag'></label>
                          </div>
                        </div>

                        <Button isSubmitting={isSubmitting} />
                      </form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  )
}

export default WaitingRoom
