import axios from 'axios'
import url from 'url'
import Storage from './storage'
import {API_ROUTES, STORAGE_KEY} from '../Constants'

const {BASE_URL, API_VERSION} = API_ROUTES
const API_BASE_URL = url.format(BASE_URL + API_VERSION)

export const ApiGet = (
  url: string,
  params: any = {},
  options: any = {},
  abortController: any = null
) => {
  params = params === null || params === undefined ? {} : params
  return new Promise((resolve, reject) => {
    let config: any = {
      params,
      ...getHttpOptions(options, true),
    }

    if (abortController) {
      config = {...config, signal: abortController.signal}
    }
    axios
      .get(`${API_BASE_URL}/${url}`, config)
      .then((responseJson) => {
        resolve(responseJson.data)
      })
      .catch((error: any) => {
        reject({
          code: error?.response?.status,
          error: error?.response?.data,
        })
      })
  })
}

export const ApiPost = (url: string, fromData: any = {}, options: any = {}) => {
  fromData = fromData === null || fromData === undefined ? {} : fromData
  return new Promise((resolve, reject) => {
    axios
      .post(`${API_BASE_URL}/${url}`, fromData, getHttpOptions(options, true))
      .then((responseJson) => {
        resolve(responseJson.data)
      })
      .catch((error: any) => {
        reject({
          code: error?.response?.status,
          error: error?.response?.data,
        })
      })
  })
}

export const ApiPut = (url: string, fromData: any = {}, options: any = {}) => {
  fromData = fromData === null || fromData === undefined ? {} : fromData
  return new Promise((resolve, reject) => {
    axios
      .put(`${API_BASE_URL}/${url}`, fromData, getHttpOptions(options, true))
      .then((responseJson) => {
        resolve(responseJson.data)
      })
      .catch((error: any) => {
        reject({
          code: error?.response?.status,
          error: error?.response?.data,
        })
      })
  })
}

export const ApiDelete = (url: string, data: any = {}, options: any = {}) => {
  data = data === null || data === undefined ? {} : data
  return new Promise((resolve, reject) => {
    axios
      .delete(`${API_BASE_URL}/${url}`, {
        data,
        ...getHttpOptions(options, true),
      })
      .then((responseJson) => {
        resolve(responseJson.data)
      })
      .catch((error: any) => {
        reject({
          code: error?.response?.status,
          error: error?.response?.data,
        })
      })
  })
}

export const ApiGetNoAuth = (url: string, params: any = {}, options: any = {}) => {
  params = params === null || params === undefined ? {} : params
  return new Promise((resolve, reject) => {
    axios
      .get(`${API_BASE_URL}/${url}`, {
        params,
        ...getHttpOptions(options),
      })
      .then((responseJson) => {
        resolve(responseJson.data)
      })
      .catch((error: any) => {
        reject({
          code: error?.response?.status,
          error: error?.response?.data,
        })
      })
  })
}

export const ApiPostNoAuth = (url: string, fromData: any, options: any = {}) => {
  console.log('API_BASE_URL', API_BASE_URL)
  fromData = fromData === null || fromData === undefined ? {} : fromData
  return new Promise((resolve, reject) => {
    axios
      .post(`${API_BASE_URL}/${url}`, fromData, getHttpOptions(options))
      .then((responseJson) => {
        resolve(responseJson.data)
      })
      .catch((error: any) => {
        reject({
          code: error?.response?.status,
          error: error?.response?.data,
        })
      })
  })
}

const getHttpOptions = (options: any, isAuth: boolean = false) => {
  let headers = {
    Authorization: '',
    'Content-Type': 'application/json',
  }
  if (isAuth) {
    headers['Authorization'] =
      'Bearer ' + Storage.getToken() ?? JSON.parse(Storage.get(STORAGE_KEY.User) ?? 'null')?.token
  }
  if (options.hasOwnProperty('Content-Type')) {
    headers['Content-Type'] = options['Content-Type'] ?? ''
  }
  return {headers}
}
