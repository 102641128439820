import React from 'react'

import {StyledLayout} from './Styled'
import MeetingFormSelector from '../../container/MeetingFormSelector'
import {VersionLabel} from '../../../../Utils/VersionLabel'

const Home: React.FC = () => (
  <StyledLayout>
    <MeetingFormSelector />
  </StyledLayout>
)

export default Home
