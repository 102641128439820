import React from 'react'

import {Flex, Heading, PrimaryButton} from 'amazon-chime-sdk-component-library-react'

import {useAppState} from '../../providers/AppStateProvider'
import {StyledList} from './Styled'

const MeetingDetails = () => {
  const {meetingId, toggleTheme, theme, region} = useAppState()

  return (
    <Flex container layout='fill-space-centered'>
      <Flex
        style={{
          marginBottom: '2rem',
          padding: '0px 1rem 0px 1rem',
        }}
        //  mr={{md: '2rem'}} px='1rem'
      >
        <Heading
          level={4}
          tag='h1'
          // mb={2}
        >
          Meeting information
        </Heading>
        <StyledList>
          <div>
            <dt>Meeting ID</dt>
            <dd>{meetingId}</dd>
          </div>
          <div>
            <dt>Hosted region</dt>
            <dd>{region}</dd>
          </div>
        </StyledList>
        <PrimaryButton
          // mt={4}
          style={{
            marginTop: '1rem',
          }}
          label={theme === 'light' ? 'Dark mode' : 'Light mode'}
          onClick={toggleTheme}
        ></PrimaryButton>
      </Flex>
    </Flex>
  )
}

export default MeetingDetails
