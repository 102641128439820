import {STORAGE_KEY} from '../Constants'

class Storage {
  static set(key: string, data: any) {
    localStorage.setItem(key, data)
  }

  static setJson(key: string, data: any) {
    localStorage.setItem(key, JSON.stringify(data))
  }

  static get(key: string) {
    return localStorage.getItem(key)
  }

  static getJson(key: string) {
    const data: any = localStorage.getItem(key)
    return JSON.parse(data)
  }

  static delete(key: string) {
    localStorage.removeItem(key)
  }

  // Others
  static getToken() {
    return localStorage.getItem(STORAGE_KEY.Token)
  }

  static isUserAuthenticated() {
    return localStorage.getItem(STORAGE_KEY.Token) !== null
  }

  static deauthenticateUser() {
    localStorage.removeItem(STORAGE_KEY.Token)
  }
}

export default Storage
