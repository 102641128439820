import {useContext} from 'react'
import {useLocalStorage} from './useLocalStorage'
import {AuthContext} from '../Context/AuthContext'
import {User} from '../Types'
import {STORAGE_KEY} from '../Constants'

// NOTE: optimally move this into a separate file

export const useUser = () => {
  const {user, setUser} = useContext(AuthContext)
  const {setItem, removeItem} = useLocalStorage()

  const addUser = (user: User | null) => {
    setUser(user)
    if (user) {
      setItem(STORAGE_KEY.User, JSON.stringify(user))
    } else {
      removeItem(STORAGE_KEY.User)
    }
  }

  const removeUser = () => {
    setUser(null)
    setItem(STORAGE_KEY.User, '')
  }

  return {user, addUser, removeUser, setUser}
}
