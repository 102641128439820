export const BASE_URL = `http://ded3138.inmotionhosting.com:3010`
export const API_VERSION = `/api`

// User API
export const RegisterUser = `website/user`
export const LoginUser = `website/user/auth/login`
export const ForgotPassword = `website/user/auth/forgot-password`
export const ResetPassword = `website/user/auth/reset-password`
export const UserPasswordChange = `website/user/password/change`
export const UserProfileChange = `website/user/update/profileImage`
export const UserSettingsChange = `website/user`
export const OTPSend = `website/user/auth/send-otp`
export const OTPVerify = `website/user/auth/otp-verify`
export const RegisterOTPSent = `website/user/register/otp-sent`
export const RegisterOTPVerify = `website/user/register/otp-verify`

// Admin API
export const LoginAdminUser = `portal/admin/auth/login`
export const AdminChangePassword = `portal/admin/password/change`
export const GlobalSetting = `portal/global-setting`
export const EmailTemplate = `portal/email-template`
export const DynamicPage = `portal/dynamic-page`
export const Plan = `portal/plan`
export const AdminUser = `portal/admin/users`
export const Role = `portal/role`
