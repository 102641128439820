import React, {useEffect, useState} from 'react'
import {UserSettings} from '../../Types'
import {getSettingData} from '../../common/api'
import {toast} from 'react-toastify'
import {API_ROUTES} from '../../Constants'
import {ApiPut} from '../../service/api'
import {Formik} from 'formik'
import Button from '../../Components/Button'

const MeetingInvitations = () => {
  const intialData = {
    meetingInvitationType: '',
  }
  const [formikData, setFormikData] = useState(intialData)
  const abortController = new AbortController()

  const getData = async () => {
    const response: UserSettings = await getSettingData(abortController)
    setFormikData({
      meetingInvitationType: response.meetingInvitationType ?? '',
    })
  }

  useEffect(() => {
    getData()

    return () => {
      abortController.abort()
    }
  }, [])
  return (
    <main>
      <section className='settings'>
        <div className='container-fluid px-0'>
          <div className='settings-wrapper'>
            <div className='settings-content' role='presentation'>
              <div className='bar' role='presentation'>
                <button type='button' className='btn button-light-primary'>
                  <span className='fa fa-chevron-left me-2' aria-hidden='true'></span>All settings
                </button>
              </div>
              <div className='panel panel--settings' aria-labelledby='contact_details_heading'>
                <div className='container' role='presentation'>
                  <h2 className='panel__header'>Meeting Invitations</h2>
                  <h5 className='mt-3 fw-normal'>Email Invitations</h5>
                  <p role='note'>
                    Choose what style of meeting invitation emails you would like to be sent.
                  </p>
                  <Formik
                    initialValues={formikData}
                    enableReinitialize
                    onSubmit={async (values, {setSubmitting}) => {
                      try {
                        const responseData: any = await ApiPut(
                          API_ROUTES.UserSettingsChange,
                          values
                        )

                        toast.success(responseData?.message)
                        setSubmitting(false)
                      } catch (error: any) {
                        toast.error(error?.error?.message)
                        setSubmitting(false)
                      }
                    }}
                  >
                    {({values, setFieldValue, handleSubmit, isSubmitting}) => (
                      <form onSubmit={handleSubmit}>
                        <div className='form-check'>
                          <input
                            className='form-check-input me-2'
                            type='radio'
                            name='Email'
                            id='Email'
                            onChange={(e) => {
                              setFieldValue('meetingInvitationType', 'Email')
                            }}
                            checked={values.meetingInvitationType === 'Email'}
                          />
                          <label className='form-check-label fw-bold' htmlFor='Email'>
                            Email Invitations
                          </label>
                          <p>
                            Invitations will be sent as an email. Participants can choose to add the
                            meeting into their calendars. Participants can RSVP through links in the
                            email.
                          </p>
                        </div>
                        <div className='form-check'>
                          <input
                            className='form-check-input me-2'
                            type='radio'
                            name='Calendar'
                            id='Calendar'
                            onChange={(e) => {
                              setFieldValue('meetingInvitationType', 'Calendar')
                            }}
                            checked={values.meetingInvitationType === 'Calendar'}
                          />
                          <label className='form-check-label fw-bold' htmlFor='Calendar'>
                            Calendar Request Invitations
                          </label>
                          <p>
                            Invitations will be sent as a calendar appointment request. This is just
                            like inviting participants to a meeting using your own calendar. These
                            invitations will automatically be added to your participant's calendar.
                            Participants can RSVP using the controls in their email and calendar
                            applications.
                          </p>
                        </div>

                        <Button isSubmitting={isSubmitting} />
                      </form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  )
}

export default MeetingInvitations
