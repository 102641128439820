import {Navigate, Route, Routes} from 'react-router-dom'
import About from '../Pages/About'
import Contact from '../Pages/Contact'
import Login from '../Pages/Auth/Login'
import Register from '../Pages/Auth/Register'
import ForgotPassword from '../Pages/Auth/ForgotPassword'
import ResetPassword from '../Pages/Auth/ResetPassword'
import SettingLayout from '../Components/layout/SettingLayout'
import Account from '../Pages/Setting/Account'
import Picture from '../Pages/Setting/Picture'
import Password from '../Pages/Setting/Password'
import Twofactor from '../Pages/Setting/Twofactor'
import Timezone from '../Pages/Setting/Timezone'
import AccountSettings from '../Pages/Setting/AccountSettings'
import {KEYWORDS, ROUTES} from '../Constants'
import Home from '../Pages/Home'
import SystemNotifications from '../Pages/Setting/SystemNotifications'
import WaitingRoom from '../Pages/Setting/WaitingRoom'
import HoldMusic from '../Pages/Setting/HoldMusic'
import ChimesNameAnnounce from '../Pages/Setting/ChimesNameAnnounce'
import SMSNotifications from '../Pages/Setting/SMSNotifications'
import MeetingInvitations from '../Pages/Setting/MeetingInvitations'
import EmailNotifications from '../Pages/Setting/EmailNotifications'
import MeetingMode from '../Pages/Setting/MeetingMode'
import PrivateRoutes from './PrivateRoutes'
import PublicRoutes from './PublicRoutes'
import {useAuth} from '../Hooks/useAuth'
import MasterLayout from '../Components/layout/MasterLayout'
import AdminLayout from '../Components/layout/AdminLayout'
import AdminLogin from '../Pages/Admin/Auth/Login'
import AdminSideBarLayout from '../Components/layout/AdminSideBarLayout'
import AdminDashboard from '../Pages/Admin/Dashboard'
import ModifyPlans from '../Pages/Admin/Plans/Modify'
import AdminUsers from '../Pages/Admin/Users/List'
import ModifyUsers from '../Pages/Admin/Users/Modify'
import AdminPlans from '../Pages/Admin/Plans/List'
import AdminPassword from '../Pages/Admin/Password'
import AdminSettings from '../Pages/Admin/Settings'
import AdminPayments from '../Pages/Admin/Payments'
import AdminEmails from '../Pages/Admin/Emails/List'
import ModifyEmails from '../Pages/Admin/Emails/Modify'
import AdminPages from '../Pages/Admin/Pages/List'
import ModifyPages from '../Pages/Admin/Pages/Modify'
import UserLayout from '../Components/layout/UserLayout'
import MettingRoom from '../Pages/AWSChime/MettingRoom'
import NoMeetingRedirect from '../Pages/AWSChime/container/NoMeetingRedirect'
import {DeviceSetup, Home as ChimeHome} from '../Pages/AWSChime/views'
import {MeetingModeSelector} from '../Pages/AWSChime/container/MeetingProviderWrapper'

export default function RoutesData() {
  const {user} = useAuth()

  const getRoutesData = () => {
    if (user?.role?.name === KEYWORDS.Roles.User) {
      return user?.token ? `/${ROUTES.User}/${ROUTES.Home}` : `/${ROUTES.User}/${ROUTES.Login}`
    }
    if (user?.role?.name === KEYWORDS.Roles.SuperAdmin) {
      return user?.token
        ? `/${ROUTES.Admin}/${ROUTES.AdminDashboard}`
        : `/${ROUTES.Admin}/${ROUTES.AdminLogin}`
    }
    return user?.token ? `/${ROUTES.User}/${ROUTES.Home}` : `/${ROUTES.User}/${ROUTES.Login}`
  }

  return (
    <Routes>
      {/* User routes */}
      <Route path={ROUTES.User} element={<UserLayout />}>
        <Route element={<PublicRoutes />}>
          <Route path={ROUTES.Login} element={<Login />} />
          <Route path={ROUTES.Signup} element={<Register />} />
          <Route path={ROUTES.ForgotPassword} element={<ForgotPassword />} />
          <Route path={ROUTES.ResetPassword} element={<ResetPassword />} />
        </Route>
        <Route element={<PrivateRoutes />}>
          <Route element={<MasterLayout />}>
            <Route path={ROUTES.Home} element={<Home />} />
            <Route path={ROUTES.About} element={<About />} />
            <Route path={ROUTES.Contact} element={<Contact />} />
            <Route path={ROUTES.Setting} element={<SettingLayout />}>
              <Route path={ROUTES.Account} element={<Account />} />
              <Route path={ROUTES.Picture} element={<Picture />} />
              <Route path={ROUTES.Password} element={<Password />} />
              <Route path={ROUTES.TwoFactor} element={<Twofactor />} />
              <Route path={ROUTES.Timezone} element={<Timezone />} />
              <Route path={ROUTES.SystemNotifications} element={<SystemNotifications />} />
              <Route path={ROUTES.AccountSettings} element={<AccountSettings />} />
              <Route path={ROUTES.MeetingMode} element={<MeetingMode />} />
              <Route path={ROUTES.WaitingRoom} element={<WaitingRoom />} />
              <Route path={ROUTES.HoldMusic} element={<HoldMusic />} />
              <Route path={ROUTES.ChimesNameAnnounce} element={<ChimesNameAnnounce />} />
              <Route path={ROUTES.SMSNotifications} element={<SMSNotifications />} />
              <Route path={ROUTES.MeetingInvitations} element={<MeetingInvitations />} />
              <Route path={ROUTES.EmailNotifications} element={<EmailNotifications />} />
              <Route
                index
                element={
                  <Navigate
                    to={`/${ROUTES.User}/${ROUTES.Setting}/${ROUTES.Account}`}
                    replace={true}
                  />
                }
              />
            </Route>
          </Route>
          <Route path='*' element={<Navigate to={getRoutesData()} replace={true} />} />
        </Route>
      </Route>

      <Route path={ROUTES.ConfCall + '/:id'} element={<MettingRoom />}>
        <Route path='home' element={<ChimeHome />} />
        <Route
          path='devices'
          element={
            <NoMeetingRedirect>
              <DeviceSetup />
            </NoMeetingRedirect>
          }
        ></Route>
        <Route
          path={`meeting/:meetingId`}
          element={
            <NoMeetingRedirect>
              <MeetingModeSelector />
            </NoMeetingRedirect>
          }
        ></Route>
      </Route>

      <Route index element={<Navigate to={getRoutesData()} replace={true} />} />

      <Route path='*' element={<Navigate to={getRoutesData()} replace={true} />} />

      {/* Admin Routes */}
      <Route path={ROUTES.Admin} element={<AdminLayout />}>
        <Route element={<PublicRoutes />}>
          <Route path={ROUTES.AdminLogin} element={<AdminLogin />} />
        </Route>
        <Route element={<PrivateRoutes />}>
          <Route element={<AdminSideBarLayout />}>
            <Route path={ROUTES.AdminDashboard} element={<AdminDashboard />} />
            <Route path={ROUTES.AdminPlans}>
              <Route index element={<AdminPlans />} />
              <Route path={ROUTES.Create} element={<ModifyPlans />} />
              <Route path={ROUTES.Edit + '/:id'} element={<ModifyPlans />} />
            </Route>
            <Route path={ROUTES.AdminUsers}>
              <Route index element={<AdminUsers />} />
              <Route path={ROUTES.Create} element={<ModifyUsers />} />
              <Route path={ROUTES.Edit + '/:id'} element={<ModifyUsers />} />
            </Route>
            <Route path={ROUTES.AdminPayments} element={<AdminPayments />} />
            <Route path={ROUTES.AdminEmails}>
              <Route index element={<AdminEmails />} />
              <Route path={ROUTES.Create} element={<ModifyEmails />} />
              <Route path={ROUTES.Edit + '/:id'} element={<ModifyEmails />} />
            </Route>
            <Route path={ROUTES.AdminPages}>
              <Route index element={<AdminPages />} />
              <Route path={ROUTES.Create} element={<ModifyPages />} />
              <Route path={ROUTES.Edit + '/:id'} element={<ModifyPages />} />
            </Route>
            <Route path={ROUTES.AdminSettings} element={<AdminSettings />} />
            <Route path={ROUTES.AdminPassword} element={<AdminPassword />} />
            <Route index element={<Navigate to={getRoutesData()} replace={true} />} />
          </Route>
        </Route>
        <Route path='*' element={<Navigate to={getRoutesData()} replace={true} />} />
      </Route>
    </Routes>
  )
}
