import React, {useEffect, useState} from 'react'
import {UserSettings} from '../../Types'
import {getSettingData} from '../../common/api'
import {Formik} from 'formik'
import {ApiPut} from '../../service/api'
import {API_ROUTES} from '../../Constants'
import {toast} from 'react-toastify'
import Button from '../../Components/Button'

const AccountSettings = () => {
  const intialData = {
    dashboardType: '',
  }
  const [formikData, setFormikData] = useState(intialData)
  const abortController = new AbortController()

  const getData = async () => {
    const response: UserSettings = await getSettingData(abortController)
    setFormikData({
      dashboardType: response.dashboardType ?? 'video',
    })
  }

  useEffect(() => {
    getData()

    return () => {
      abortController.abort()
    }
  }, [])

  return (
    <main>
      <section className='settings'>
        <div className='container-fluid px-0'>
          <div className='settings-wrapper'>
            <div className='settings-content' role='presentation'>
              <div className='bar' role='presentation'>
                <button type='button' className='btn button-light-primary'>
                  <span className='fa fa-chevron-left me-2' aria-hidden='true'></span>All settings
                </button>
              </div>
              <div className='panel panel--settings' aria-labelledby='contact_details_heading'>
                <div className='container' role='presentation'>
                  <h2 className='panel__header'>Account Settings</h2>
                  <Formik
                    initialValues={formikData}
                    enableReinitialize
                    onSubmit={async (values, {setSubmitting}) => {
                      try {
                        const responseData: any = await ApiPut(
                          API_ROUTES.UserSettingsChange,
                          values
                        )

                        toast.success(responseData?.message)
                        setSubmitting(false)
                      } catch (error: any) {
                        toast.error(error?.error?.message)
                        setSubmitting(false)
                      }
                    }}
                  >
                    {({values, handleChange, handleBlur, handleSubmit, isSubmitting}) => (
                      <form onSubmit={handleSubmit}>
                        <div className='form-group d-flex align-items-center gap-2'>
                          <span>Dashboard type</span>
                          <select
                            id='dashboardType'
                            className='form-select valid'
                            name='dashboardType'
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.dashboardType}
                            style={{
                              width: '200px',
                              borderRadius: '20px',
                            }}
                          >
                            <option value='video'>Video</option>
                            <option value='audio'>Audio</option>
                          </select>
                        </div>

                        <Button isSubmitting={isSubmitting} />
                      </form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  )
}

export default AccountSettings
