import React, {useEffect, useState} from 'react'
import {Link, useNavigate, useParams} from 'react-router-dom'
import {API_ROUTES, ROUTES} from '../../../Constants'
import {ApiGet, ApiPost, ApiPut} from '../../../service/api'
import {ErrorMessage, Formik} from 'formik'
import {toast} from 'react-toastify'
import * as Yup from 'yup'
import Button from '../../../Components/Button'

const ModifyPlans = () => {
  const {id} = useParams()
  const intialData = {
    planName: '',
    description: '',
    price: '',
    features: '',
    plusFeatures: '',
    capacity: '',
    signupUrl: '',
    buttonText: '',
    baseValue: '',
    planType: '',
    status: '',
  }
  const navigate = useNavigate()
  const [formikData, setFormikData] = useState(intialData)

  const Schema = Yup.object().shape({
    planName: Yup.string().required('Name is required'),
    description: Yup.string().required('Description is required'),
    price: Yup.string().required('Price is required'),
    features: Yup.string().required('Features is required'),
    plusFeatures: Yup.string().required('Plus Features is required'),
    capacity: Yup.string().required('Capacity is required'),
    signupUrl: Yup.string().required('Signup URL is required'),
    buttonText: Yup.string().required('Button Text is required'),
    baseValue: Yup.string().required('Best Value is required'),
    planType: Yup.string().required('Plan Type is required'),
    status: Yup.string().required('Trial Days is required'),
  })

  const getData = async () => {
    try {
      const response: any = await ApiGet(`${API_ROUTES.Plan}/${id}`)
      setFormikData({
        planName: response?.data?.planName,
        description: response?.data?.description,
        price: response?.data?.price,
        features: response?.data?.features,
        plusFeatures: response?.data?.plusFeatures,
        capacity: response?.data?.capacity,
        signupUrl: response?.data?.signupUrl,
        buttonText: response?.data?.buttonText,
        baseValue: response?.data?.baseValue,
        planType: response?.data?.planType,
        status: response?.data?.status,
      })
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (id) getData()
  }, [])
  return (
    <div id='content' className='p-3 pt-1'>
      <div className='container '>
        <div className='input-group mb-3 justify-content-end'>
          <a href='#' className='btn btn-primary'>
            Back
          </a>
        </div>
        <div className='card'>
          <div className='card-header'>
            <h2 className='mb-0'>{id ? 'Edit' : 'Add'} Plan</h2>
          </div>
          <div className='card-body'>
            <Formik
              initialValues={formikData}
              enableReinitialize
              validationSchema={Schema}
              onSubmit={async (values, {setSubmitting}) => {
                try {
                  if (id) {
                    const responseData: any = await ApiPut(`${API_ROUTES.Plan}/${id}`, values)
                    toast.success(responseData?.message)
                  } else {
                    const responseData: any = await ApiPost(API_ROUTES.Plan, values)
                    toast.success(responseData?.message)
                  }
                  navigate(`/${ROUTES.Admin}/${ROUTES.AdminPlans}`)
                  setSubmitting(false)
                } catch (error: any) {
                  toast.error(error?.error?.message)
                  setSubmitting(false)
                }
              }}
            >
              {({values, handleChange, handleBlur, handleSubmit, isSubmitting, errors}) => (
                <form onSubmit={handleSubmit}>
                  <div className='form-group'>
                    <label htmlFor='planName'>Name:</label>
                    <input
                      type='text'
                      className='form-control elegant-input'
                      id='planName'
                      name='planName'
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.planName}
                    />
                    <ErrorMessage name='planName' component='div' className='red-error' />
                  </div>

                  <div className='form-group'>
                    <label htmlFor='description'>Description:</label>
                    <textarea
                      className='form-control elegant-input'
                      id='description'
                      name='description'
                      rows={8}
                      style={{
                        resize: 'none',
                      }}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.description}
                    />
                    <ErrorMessage name='description' component='div' className='red-error' />
                  </div>

                  <div className='form-group'>
                    <label htmlFor='price'>Price:</label>
                    <input
                      type='text'
                      className='form-control elegant-input'
                      id='price'
                      name='price'
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.price}
                    />
                    <ErrorMessage name='price' component='div' className='red-error' />
                  </div>

                  <div className='form-group'>
                    <label htmlFor='status'>Is Active:</label>
                    <select
                      className='form-control elegant-input'
                      name='status'
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.status}
                    >
                      <option value=''>Select Status</option>
                      <option value='active'>Active</option>
                      <option value='inactive'>In Active</option>
                    </select>
                    <ErrorMessage name='status' component='div' className='red-error' />
                  </div>
                  <div className='form-group'>
                    <label htmlFor='features'>Features:</label>
                    <textarea
                      className='form-control elegant-textarea elegant-input'
                      id='features'
                      name='features'
                      rows={5}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.features}
                      style={{
                        height: '100px',
                      }}
                    />
                    <ErrorMessage name='features' component='div' className='red-error' />
                  </div>

                  <div className='form-group'>
                    <label htmlFor='plusFeatures'>Plus Features:</label>
                    <textarea
                      className='form-control elegant-textarea elegant-input'
                      id='plusFeatures'
                      name='plusFeatures'
                      rows={5}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.plusFeatures}
                      style={{
                        height: '100px',
                      }}
                    />
                    <ErrorMessage name='plusFeatures' component='div' className='red-error' />
                  </div>
                  <div className='form-group'>
                    <label htmlFor='capacity'>Capacity:</label>
                    <input
                      type='text'
                      className='form-control elegant-input'
                      id='capacity'
                      name='capacity'
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.capacity}
                    />
                    <ErrorMessage name='capacity' component='div' className='red-error' />
                  </div>

                  <div className='form-group'>
                    <label htmlFor='signupUrl'>Signup URL:</label>
                    <input
                      type='text'
                      className='form-control elegant-input'
                      id='signupUrl'
                      name='signupUrl'
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.signupUrl}
                    />
                    <ErrorMessage name='signupUrl' component='div' className='red-error' />
                  </div>

                  <div className='form-group'>
                    <label htmlFor='buttonText'>Button Text:</label>
                    <input
                      type='text'
                      className='form-control elegant-input'
                      id='buttonText'
                      name='buttonText'
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.buttonText}
                    />
                    <ErrorMessage name='buttonText' component='div' className='red-error' />
                  </div>

                  <div className='form-group'>
                    <label htmlFor='baseValue'>Best Value:</label>
                    <input
                      type='text'
                      className='form-control elegant-input'
                      id='baseValue'
                      name='baseValue'
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.baseValue}
                    />
                    <ErrorMessage name='baseValue' component='div' className='red-error' />
                  </div>

                  <div className='form-group'>
                    <label htmlFor='planType'>Plan Type:</label>
                    <select
                      className='form-control elegant-input'
                      name='planType'
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.planType}
                    >
                      <option value=''>Select Plan Type</option>
                      <option value='Free'>Free</option>
                      <option value='Subscription'>Subscription</option>
                    </select>
                    <ErrorMessage name='planType' component='div' className='red-error' />
                  </div>

                  <Button isSubmitting={isSubmitting} text={id ? 'Update' : 'Add'} />
                </form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ModifyPlans
