import React from 'react'

const Button = ({isSubmitting = false, text = 'Save'}) => {
  return (
    <div className='form-group'>
      <button className='btn btn-primary' disabled={isSubmitting}>
        {' '}
        {isSubmitting ? (
          <div
            className='spinner-border text-light'
            style={{
              height: '20px',
              width: '20px',
              verticalAlign: 'text-bottom',
            }}
            role='status'
          >
            <span className='visually-hidden'>Loading...</span>
          </div>
        ) : (
          text
        )}
      </button>
    </div>
  )
}

export default Button
