import {ErrorMessage, Formik} from 'formik'
import React from 'react'
import * as Yup from 'yup'
import {useNavigate, useSearchParams} from 'react-router-dom'
import {API_ROUTES, ROUTES} from '../../Constants'
import {toast} from 'react-toastify'
import {ApiPostNoAuth} from '../../service/api'

const ResetPassword = () => {
  const navigate = useNavigate()
  let [searchParams] = useSearchParams()

  const intialData = {
    email: searchParams.get('email') ?? '',
    password: '',
    cPassword: '',
  }

  const Schema = Yup.object().shape({
    password: Yup.string()
      .min(8, 'Password must be at least 8 characters')
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]+$/,
        'Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character'
      )
      .required('Password is required'),
    cPassword: Yup.string()
      .test('passwords-match', 'Passwords must match', function (value) {
        return this.parent.password === value
      })
      .required('Confirm password is required'),
  })

  return (
    <>
      <main>
        <section className='login-wrapper'>
          <div className='container'>
            <div className='logo'>
              <a href='!#'>
                <img src='/images/logo-new-scaled.jpg' alt='logo' height='auto' width='250' />
              </a>
            </div>
            <div className='login-form-box'>
              <h3 className='mb-4'>Reset Password</h3>
              <div className='d-flex justify-content-center align-items-center h-50'>
                <Formik
                  initialValues={intialData}
                  validationSchema={Schema}
                  onSubmit={async (values, {setSubmitting}) => {
                    console.log('values', values)
                    try {
                      const resetData: any = await ApiPostNoAuth(API_ROUTES.ResetPassword, {
                        token: searchParams.get('token'),
                        password: values?.password,
                      })
                      navigate(`/${ROUTES.User}/${ROUTES.Login}`)
                      toast.success(resetData?.message)
                      setSubmitting(false)
                    } catch (error: any) {
                      toast.error(error?.error?.message)
                      setSubmitting(false)
                    }
                  }}
                >
                  {({values, handleChange, handleBlur, handleSubmit, isSubmitting}) => (
                    <form onSubmit={handleSubmit}>
                      <div className='row'>
                        <div className='col-12 mt-2'>
                          <div className='form-group'>
                            <input
                              type='email'
                              name='email'
                              readOnly
                              autoComplete='off'
                              className='form-control'
                              placeholder='Email'
                              value={values.email}
                            />
                          </div>
                        </div>
                        <div className='col-12'>
                          <div className='form-group position-relative'>
                            <i className='far fa-eye control-icon without-label'></i>
                            <input
                              type='password'
                              name='password'
                              className='form-control pe-5'
                              placeholder='New Password'
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.password}
                            />
                            <ErrorMessage name='password' component='div' className='red-error' />
                          </div>
                        </div>
                        <div className='col-12 position-relative'>
                          <div className='form-group position-relative'>
                            <i className='far fa-eye control-icon without-label'></i>
                            <input
                              type='password'
                              name='cPassword'
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.cPassword}
                              className='form-control pe-5'
                              placeholder='Confirm New Password'
                            />
                            <ErrorMessage name='cPassword' component='div' className='red-error' />
                          </div>
                        </div>
                        <div className='col-12 login-btn-wrapper text-center'>
                          <div className='form-group mb-0'>
                            <button
                              type='submit'
                              name='login'
                              value='login'
                              disabled={isSubmitting}
                              className='btn login-btn'
                            >
                              {isSubmitting ? (
                                <div
                                  className='spinner-border text-light'
                                  style={{
                                    height: '20px',
                                    width: '20px',
                                    verticalAlign: 'text-bottom',
                                  }}
                                  role='status'
                                >
                                  <span className='visually-hidden'>Loading...</span>
                                </div>
                              ) : (
                                <> Reset Password </>
                              )}
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </section>
      </main>

      <footer>
        <div className='footer-logo-wrapper'>
          <h5>Powered by</h5>
          <img src='/images/aws.png' alt='aws logo' />
        </div>
      </footer>
    </>
  )
}

export default ResetPassword
