import React, {FC, PropsWithChildren} from 'react'
import {BrowserRouter as Router, useParams} from 'react-router-dom'
import {ThemeProvider} from 'styled-components'
import {
  NotificationProvider,
  GlobalStyles,
  LoggerProvider,
} from 'amazon-chime-sdk-component-library-react'
import meetingConfig from '../../config/meetingConfig'
import {AppStateProvider, useAppState} from './providers/AppStateProvider'
import ErrorProvider from './providers/ErrorProvider'
import MeetingProviderWrapper from './container/MeetingProviderWrapper'
import Notifications from './container/Notifications'
import {demoDarkTheme, demoLightTheme} from '../../theme/demoTheme'

const MettingRoom = () => {
  const {id} = useParams()
  return (
    <LoggerProvider logger={meetingConfig.logger}>
      <AppStateProvider>
        <Theme>
          <NotificationProvider>
            <Notifications />
            <ErrorProvider>
              <MeetingProviderWrapper />
            </ErrorProvider>
          </NotificationProvider>
        </Theme>
      </AppStateProvider>
    </LoggerProvider>
  )
}

const Theme: React.FC<PropsWithChildren> = ({children}) => {
  const {theme} = useAppState()

  return (
    <ThemeProvider theme={theme === 'light' ? demoLightTheme : demoDarkTheme}>
      <GlobalStyles />
      {children}
    </ThemeProvider>
  )
}

export default MettingRoom
