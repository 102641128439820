import React from 'react'
import {Outlet} from 'react-router-dom'
import AdminSidebar from '../../Pages/Admin/Sidebar'

const AdminSideBarLayout = () => {
  return (
    <div className='d-flex'>
      <AdminSidebar />
    </div>
  )
}

export default AdminSideBarLayout
