import React from 'react'

const AdminPayments = () => {
  return (
    <div id='content' className='p-3 pt-1'>
      <h2 className='mb-4'>Payment List</h2>
      <div className='table-responsive'>
        <table className='table table-bordered table-hover'>
          <thead className='thead-dark'>
            <tr>
              <th>SL NO</th>
              <th>User</th>
              <th>Transaction ID</th>
              <th>Subscription ID</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1</td>
              <td>N/A</td>
              <td>ch_3ObpWgJuOF5Mj9e80QJbmmD0</td>
              <td></td>
            </tr>
            <tr>
              <td>2</td>
              <td>N/A</td>
              <td>ch_3ObjEdJuOF5Mj9e81stuun4T</td>
              <td>sub_1OZ50QJuOF5Mj9e86IYIo2fB</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default AdminPayments
