import React from 'react'
import {useLocation, useNavigate} from 'react-router-dom'
import {ROUTES} from '../../Constants'

const Sidebar = () => {
  const {pathname} = useLocation()
  const navigate = useNavigate()

  const arrow = () => (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 320 512'>
      <path d='M310.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L242.7 256 73.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z' />
    </svg>
  )

  const handleRedirect = (url: string) => {
    navigate(`/${ROUTES.User}/${ROUTES.Setting}/${url}`)
  }

  return (
    <section className='settings'>
      <div className='container-fluid px-0'>
        <div className='settings-wrapper'>
          <div className='sidebar' aria-label='General settings menu'>
            <ul className='list' role='menubar' aria-orientation='vertical'>
              <li
                onClick={() => handleRedirect(ROUTES.Account)}
                className={`item ${pathname === `/${ROUTES.User}/${ROUTES.Setting}/${ROUTES.Account}` ? 'active' : ''} `}
                role='menuitem'
              >
                <div>Account & profile</div>
                {arrow()}
              </li>
              <ul className='list' role='menubar' aria-orientation='vertical'>
                <li
                  onClick={() => handleRedirect(ROUTES.Picture)}
                  className={`item ${pathname === `/${ROUTES.User}/${ROUTES.Setting}/${ROUTES.Picture}` ? 'active' : ''} `}
                  role='menuitem'
                >
                  <div>Picture</div>
                  {arrow()}
                </li>
                <li
                  onClick={() => handleRedirect(ROUTES.Password)}
                  className={`item ${pathname === `/${ROUTES.User}/${ROUTES.Setting}/${ROUTES.Password}` ? 'active' : ''} `}
                  role='menuitem'
                >
                  <div>Password</div>
                  {arrow()}
                </li>
                <li
                  onClick={() => handleRedirect(ROUTES.TwoFactor)}
                  className={`item ${pathname === `/${ROUTES.User}/${ROUTES.Setting}/${ROUTES.TwoFactor}` ? 'active' : ''} `}
                  role='menuitem'
                >
                  <div>Two-factor authentication</div>
                  {arrow()}
                </li>
                <li
                  onClick={() => handleRedirect(ROUTES.Timezone)}
                  className={`item ${pathname === `/${ROUTES.User}/${ROUTES.Setting}/${ROUTES.Timezone}` ? 'active' : ''} `}
                  role='menuitem'
                >
                  <div>Time zone</div>
                  {arrow()}
                </li>
                <li
                  onClick={() => handleRedirect(ROUTES.SystemNotifications)}
                  className={`item ${pathname === `/${ROUTES.User}/${ROUTES.Setting}/${ROUTES.SystemNotifications}` ? 'active' : ''} `}
                  role='menuitem'
                >
                  <div>System notifications</div>
                  {arrow()}
                </li>
                <li
                  onClick={() => handleRedirect(ROUTES.AccountSettings)}
                  className={`item ${pathname === `/${ROUTES.User}/${ROUTES.Setting}/${ROUTES.AccountSettings}` ? 'active' : ''} `}
                  role='menuitem'
                >
                  <div>Account settings</div>
                  {arrow()}
                </li>
                {/* <li className='item' role='menuitem'>
                  <div>Audio & video settings</div>
                  {arrow()}
                </li>
                <li className='item' role='menuitem'>
                  <div>Virtual backgrounds</div>
                  {arrow()}
                </li> */}
              </ul>
              <li
                onClick={() => handleRedirect(ROUTES.MeetingMode)}
                className={`item ${pathname === `/${ROUTES.User}/${ROUTES.Setting}/${ROUTES.MeetingMode}` ? 'active' : ''} `}
                role='menuitem'
              >
                <div>Meeting mode</div>
                {arrow()}
              </li>
              <ul className='list' role='menubar' aria-orientation='vertical'>
                <li
                  onClick={() => handleRedirect(ROUTES.WaitingRoom)}
                  className={`item ${pathname === `/${ROUTES.User}/${ROUTES.Setting}/${ROUTES.WaitingRoom}` ? 'active' : ''} `}
                  role='menuitem'
                >
                  <div>Waiting room</div>
                  {arrow()}
                </li>
                <li
                  onClick={() => handleRedirect(ROUTES.HoldMusic)}
                  className={`item ${pathname === `/${ROUTES.User}/${ROUTES.Setting}/${ROUTES.HoldMusic}` ? 'active' : ''} `}
                  role='menuitem'
                >
                  <div>Hold music playlist</div>
                  {arrow()}
                </li>
                <li
                  onClick={() => handleRedirect(ROUTES.ChimesNameAnnounce)}
                  className={`item ${pathname === `/${ROUTES.User}/${ROUTES.Setting}/${ROUTES.ChimesNameAnnounce}` ? 'active' : ''} `}
                  role='menuitem'
                >
                  <div>Chimes &amp; name announce</div>
                  {arrow()}
                </li>
                {/* <li className='item' role='menuitem'>
                  <div>Primary dial-in numbers</div>
                  {arrow()}
                </li>
                <li className='item' role='menuitem'>
                  <div>Moderator PIN</div>
                  {arrow()}
                </li>
                <li className='item' role='menuitem'>
                  <div>Access code</div>
                  {arrow()}
                </li>
                <li className='item' role='menuitem'>
                  <div>Security</div>
                  {arrow()}
                </li>
                <li className='item' role='menuitem'>
                  <div>Recording & live streaming</div>
                  {arrow()}
                </li> */}
              </ul>
              <li
                onClick={() => handleRedirect(ROUTES.SMSNotifications)}
                className={`item ${pathname === `/${ROUTES.User}/${ROUTES.Setting}/${ROUTES.SMSNotifications}` ? 'active' : ''} `}
                role='menuitem'
              >
                <div>PIN-less entry & SMS notifications</div>
                {arrow()}
              </li>
              <ul className='list' role='menubar' aria-orientation='vertical'>
                <li
                  onClick={() => handleRedirect(ROUTES.MeetingInvitations)}
                  className={`item ${pathname === `/${ROUTES.User}/${ROUTES.Setting}/${ROUTES.MeetingInvitations}` ? 'active' : ''} `}
                  role='menuitem'
                >
                  <div>Meeting Invitations</div>
                  {arrow()}
                </li>
                <li
                  onClick={() => handleRedirect(ROUTES.EmailNotifications)}
                  className={`item ${pathname === `/${ROUTES.User}/${ROUTES.Setting}/${ROUTES.EmailNotifications}` ? 'active' : ''} `}
                  role='menuitem'
                >
                  <div>Email notifications</div>
                  {arrow()}
                </li>
              </ul>
            </ul>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Sidebar
