import {API_ROUTES} from '../Constants'
import {ApiGet} from '../service/api'

export const getSettingData = async (abortController: any = null) => {
  try {
    const response: any = await ApiGet(API_ROUTES.UserSettingsChange, {}, {}, abortController)
    return response?.data
  } catch (error) {
    return {}
  }
}
