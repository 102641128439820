import React, {useEffect, useState} from 'react'
import {Link, useNavigate} from 'react-router-dom'
import {ApiGetNoAuth, ApiPostNoAuth} from '../../service/api'
import {ErrorMessage, Formik} from 'formik'
import * as Yup from 'yup'
import {toast} from 'react-toastify'
import {API_ROUTES, ROUTES} from '../../Constants'
import OTPModal from './OTPModal'

const Register = () => {
  const intialData = {
    email: '',
    mobileNo: '',
    firstName: '',
    lastName: '',
    password: '',
    planId: '',
    companyName: '',
  }
  const [planList, setPlanList] = useState([])
  const [userRoleId, setUserRoleId] = useState('')
  const [otpModal, setOtpModal] = useState(false)
  const [mobileNo, setMobileNo] = useState<string | null>(null)
  const navigate = useNavigate()
  const [otpVerify, setOtpVerify] = useState(false)

  const getPlanData = async () => {
    try {
      const planData: any = await ApiGetNoAuth('v2/website/getAllPlan')
      setPlanList(planData?.data ?? [])
    } catch (error) {}
  }

  const getRolesData = async () => {
    try {
      const rolesData: any = await ApiGetNoAuth('v2/website/getDefaultRole')
      setUserRoleId(rolesData?.data?.role ?? null)
    } catch (error) {}
  }

  useEffect(() => {
    getPlanData()
    getRolesData()
  }, [])

  const Schema = Yup.object().shape({
    companyName: Yup.string().required('Companyname is required'),
    firstName: Yup.string().required('Firstname is required'),
    lastName: Yup.string().required('Lastname is required'),
    email: Yup.string().email('Invalid email').required('Email is required'),
    password: Yup.string()
      .min(8, 'Password must be at least 8 characters')
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]+$/,
        'Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character'
      )
      .required('Password is required'),
    mobileNo: Yup.string()
      .matches(/^\d+$/, 'Mobileno must contain numbers only')
      .required('Mobileno is required'),
    planId: Yup.string().required('Plan is required'),
  })

  const handleSendOtp = async (mobileNo: string) => {
    try {
      const data: any = await ApiPostNoAuth(API_ROUTES.RegisterOTPSent, {
        mobileNo: '+1' + mobileNo,
      })
      toast.success(data?.message)
    } catch (error: any) {
      toast.error(error?.error?.message)
    }
  }

  const handleOtpSubmit = async (otp: string) => {
    // Here you can perform actions like submitting OTP to a server for validation
    try {
      const data: any = await ApiPostNoAuth(API_ROUTES.RegisterOTPVerify, {
        mobileNo: '+1' + mobileNo,
        otp,
      })
      toast.success(data?.message)
      setOtpModal(false)
      setOtpVerify(true)
    } catch (error: any) {
      toast.error(error?.error?.message)
    }
  }

  return (
    <>
      <main>
        <section className='login-wrapper'>
          <div className='container'>
            <div className='logo'>
              <a href='!#'>
                <img src='/images/logo-new-scaled.jpg' alt='logo' height='auto' width='250' />
              </a>
            </div>
            <div className='register-form-box'>
              <h3 className='mb-lg-5 mb-md-5 mb-3'>Registration</h3>
              <Formik
                initialValues={intialData}
                validationSchema={Schema}
                onSubmit={async (values, {setSubmitting}) => {
                  try {
                    await ApiPostNoAuth(API_ROUTES.RegisterUser, {
                      ...values,
                      role: userRoleId,
                    })
                    navigate(`/${ROUTES.User}/${ROUTES.Login}`)
                    setSubmitting(false)
                  } catch (error: any) {
                    toast.error(error?.error?.message)
                    setSubmitting(false)
                  }
                }}
              >
                {({
                  values,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                  /* and other goodies */
                }) => (
                  <form onSubmit={handleSubmit}>
                    <div className='row'>
                      <div className='col-md-6'>
                        <div className='form-group'>
                          <label className='control-label'>
                            Company<span className='text-danger'>*</span>
                          </label>
                          <input
                            type='text'
                            name='companyName'
                            id='companyName'
                            className='form-control'
                            placeholder='Company'
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.companyName}
                          />
                          <ErrorMessage name='companyName' component='div' className='red-error' />
                        </div>
                      </div>
                      <div className='col-md-6'>
                        <div className='form-group'>
                          <label>
                            First Name<span className='text-danger'>*</span>
                          </label>
                          <input
                            type='text'
                            name='firstName'
                            className='form-control'
                            placeholder='First Name'
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.firstName}
                          />
                          <ErrorMessage name='firstName' component='div' className='red-error' />
                        </div>
                      </div>
                    </div>
                    <div className='row'>
                      <div className='col-md-6'>
                        <div className='form-group'>
                          <label>
                            Last Name<span className='text-danger'>*</span>
                          </label>
                          <input
                            type='text'
                            name='lastName'
                            className='form-control'
                            placeholder='Last Name'
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.lastName}
                          />
                          <ErrorMessage name='lastName' component='div' className='red-error' />
                        </div>
                      </div>
                      <div className='col-md-6'>
                        <div className='form-group'>
                          <label>
                            Email<span className='text-danger'>*</span>
                          </label>
                          <input
                            type='email'
                            name='email'
                            className='form-control'
                            placeholder='Email'
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.email}
                          />
                          <ErrorMessage name='email' component='div' className='red-error' />
                        </div>
                      </div>
                    </div>
                    <div className='row'>
                      <div className='col-md-6'>
                        <div className='form-group position-relative'>
                          <label>
                            Password<span className='text-danger'>*</span>
                          </label>
                          <i className='far fa-eye control-icon'></i>
                          <input
                            type='password'
                            name='password'
                            id='password'
                            className='form-control pe-5'
                            placeholder='Password'
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.password}
                          />
                          <ErrorMessage name='password' component='div' className='red-error' />
                        </div>
                      </div>
                      <div className='col-md-6'>
                        <div className='form-group'>
                          <label>
                            Mobile Number<span className='text-danger'>*</span>
                          </label>
                          <div className='input-group mb-3'>
                            <span className='input-group-text' id='basic-addon1'>
                              +1
                            </span>
                            <input
                              type='tel'
                              name='mobileNo'
                              className='form-control'
                              placeholder='Mobile Number'
                              readOnly={otpVerify}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.mobileNo}
                            />
                            <span
                              className='input-group-text'
                              onClick={() => {
                                setOtpModal(true)
                                setMobileNo(values.mobileNo)
                                handleSendOtp(values.mobileNo)
                              }}
                              style={{
                                color: '#fff',
                                background: '#ffac2e',
                                cursor: 'pointer',
                                fontWeight: 700,
                              }}
                            >
                              Send OTP
                            </span>
                          </div>
                          <ErrorMessage name='mobileNo' component='div' className='red-error' />
                        </div>
                      </div>
                    </div>
                    <div className='row'>
                      <div className='col-md-6'>
                        <div className='form-group'>
                          <label>
                            Plan<span className='text-danger'>*</span>
                          </label>
                          <select
                            id='planId'
                            className='form-select valid'
                            name='planId'
                            aria-invalid='false'
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.planId}
                          >
                            <option value=''>Select Plan</option>
                            {planList?.map((plan: {planName: string; _id: string}) => (
                              <option key={plan?._id} value={plan?._id}>
                                {plan?.planName}
                              </option>
                            ))}
                          </select>
                          <ErrorMessage name='planId' component='div' className='red-error' />
                        </div>
                      </div>
                      <div className='col-12 mt-3 login-btn-wrapper d-flex justify-content-center align-items-center'>
                        <button
                          name='submit'
                          value='login'
                          disabled={isSubmitting || !otpVerify}
                          className='btn btn-dark login-btn'
                        >
                          {isSubmitting ? (
                            <div
                              className='spinner-border text-light'
                              style={{
                                height: '20px',
                                width: '20px',
                                verticalAlign: 'text-bottom',
                              }}
                              role='status'
                            >
                              <span className='visually-hidden'>Loading...</span>
                            </div>
                          ) : (
                            <> Submit </>
                          )}
                        </button>
                        <Link to={`/${ROUTES.User}/${ROUTES.Login}`}>Already have an account?</Link>
                      </div>
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          </div>
        </section>
        <OTPModal otpModal={otpModal} setOtpModal={setOtpModal} handleSubmit={handleOtpSubmit} />
      </main>

      <footer>
        <div className='footer-logo-wrapper'>
          <h5>Powered by</h5>
          <img src='/images/aws.png' alt='aws logo' />
        </div>
      </footer>
    </>
  )
}

export default Register
