import React, {useEffect, useState} from 'react'
import {UserSettings} from '../../Types'
import {getSettingData} from '../../common/api'
import {Formik} from 'formik'
import {API_ROUTES} from '../../Constants'
import {toast} from 'react-toastify'
import {ApiPut} from '../../service/api'
import Button from '../../Components/Button'

const EmailNotifications = () => {
  const intialData = {
    meetingInvitations: false,
    meetingReminders: false,
    meetingSummaries: false,
    cueTranscriptionSummaries: false,
  }
  const [formikData, setFormikData] = useState(intialData)
  const abortController = new AbortController()

  const getData = async () => {
    const response: UserSettings = await getSettingData(abortController)
    setFormikData({
      meetingInvitations: response?.emailNotification?.meetingInvitations ?? false,
      meetingReminders: response?.emailNotification?.meetingReminders ?? false,
      meetingSummaries: response?.emailNotification?.meetingSummaries ?? false,
      cueTranscriptionSummaries: response?.emailNotification?.cueTranscriptionSummaries ?? false,
    })
  }

  useEffect(() => {
    getData()

    return () => {
      abortController.abort()
    }
  }, [])
  return (
    <main>
      <section className='settings'>
        <div className='container-fluid px-0'>
          <div className='settings-wrapper'>
            <div className='settings-content' role='presentation'>
              <div className='bar' role='presentation'>
                <button type='button' className='btn button-light-primary'>
                  <span className='fa fa-chevron-left me-2' aria-hidden='true'></span>All settings
                </button>
              </div>
              <div className='panel panel--settings' aria-labelledby='contact_details_heading'>
                <div className='container' role='presentation'>
                  <h2 className='panel__header'>Email notifications</h2>
                  <Formik
                    initialValues={formikData}
                    enableReinitialize
                    onSubmit={async (values, {setSubmitting}) => {
                      try {
                        const responseData: any = await ApiPut(API_ROUTES.UserSettingsChange, {
                          emailNotification: values,
                        })

                        toast.success(responseData?.message)
                        setSubmitting(false)
                      } catch (error: any) {
                        toast.error(error?.error?.message)
                        setSubmitting(false)
                      }
                    }}
                  >
                    {({values, setFieldValue, handleSubmit, isSubmitting}) => (
                      <form onSubmit={handleSubmit}>
                        <div className='form-check'>
                          <input
                            className='form-check-input me-2'
                            type='checkbox'
                            id='Invitations'
                            onChange={(e) => {
                              setFieldValue('meetingInvitations', e.target.checked)
                            }}
                            checked={values.meetingInvitations}
                          />
                          <label className='form-check-label fw-bold' htmlFor='Invitations'>
                            Meeting Invitations
                          </label>
                          <p>Sent to the organizer and all invitees when a meeting is scheduled.</p>
                        </div>
                        <div className='form-check'>
                          <input
                            className='form-check-input me-2'
                            type='checkbox'
                            onChange={(e) => {
                              setFieldValue('meetingReminders', e.target.checked)
                            }}
                            checked={values.meetingReminders}
                            id='Reminders'
                          />
                          <label className='form-check-label fw-bold' htmlFor='Reminders'>
                            Meeting Reminders
                          </label>
                          <p>
                            Sent 15 minutes before the scheduled start time to the organizer and all
                            invitees.
                          </p>
                        </div>
                        <div className='form-check'>
                          <input
                            className='form-check-input me-2'
                            type='checkbox'
                            onChange={(e) => {
                              setFieldValue('meetingSummaries', e.target.checked)
                            }}
                            checked={values.meetingSummaries}
                            id='Summaries'
                          />
                          <label className='form-check-label fw-bold' htmlFor='Summaries'>
                            Meeting Summaries
                          </label>
                          <p>
                            Sent to the organizer after the meeting ends. This includes a list of
                            all attendees and links to access any recordings.
                          </p>
                        </div>
                        <div className='form-check'>
                          <input
                            className='form-check-input me-2'
                            type='checkbox'
                            onChange={(e) => {
                              setFieldValue('cueTranscriptionSummaries', e.target.checked)
                            }}
                            checked={values.cueTranscriptionSummaries}
                            id='Transcription'
                          />
                          <label className='form-check-label fw-bold' htmlFor='Transcription'>
                            Cue Transcription Summaries
                          </label>
                          <p>
                            When recording a meeting, if the automatic Cue transcription option is
                            selected, a separate email is sent to let you know when the
                            transcription has completed processing.
                          </p>
                        </div>
                        <Button isSubmitting={isSubmitting} />
                      </form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  )
}

export default EmailNotifications
