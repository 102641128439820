import React, {useEffect, useState} from 'react'
import {ErrorMessage, Formik} from 'formik'
import * as Yup from 'yup'
import {API_ROUTES} from '../../Constants'
import {toast} from 'react-toastify'
import {ApiPut} from '../../service/api'
import {getSettingData} from '../../common/api'
import {UserSettings} from '../../Types'
import Button from '../../Components/Button'

const Account = () => {
  const intialData = {
    firstName: '',
    lastName: '',
    email: '',
  }
  const [formikData, setFormikData] = useState(intialData)
  const abortController = new AbortController()

  const Schema = Yup.object().shape({
    firstName: Yup.string().required('Firstname is required'),
    lastName: Yup.string().required('Lastname is required'),
    email: Yup.string().email('Invalid email').required('Email is required'),
  })

  const getData = async () => {
    const response: UserSettings = await getSettingData(abortController)
    setFormikData({
      firstName: response.firstName ?? '',
      lastName: response.lastName ?? '',
      email: response.email ?? '',
    })
  }

  useEffect(() => {
    getData()

    return () => {
      abortController.abort()
    }
  }, [])

  return (
    <main>
      <section className='settings'>
        <div className='container-fluid px-0'>
          <div className='settings-wrapper'>
            <div className='settings-content' role='presentation'>
              <div className='bar' role='presentation'>
                <button
                  type='button'
                  className='btn button-light-primary d-flex align-items-center'
                >
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    className='me-2'
                    viewBox='0 0 320 512'
                    fill='#de9b17'
                    width='8px'
                  >
                    <path d='M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l192 192c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L77.3 256 246.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-192 192z' />
                  </svg>
                  All settings
                </button>
              </div>
              <div className='panel panel--settings' aria-labelledby='contact_details_heading'>
                <div className='container' role='presentation'>
                  <h2 className='panel__header'>Contact details</h2>
                  <p role='note'>
                    Enter your name, email, and other details as you would like it to appear to
                    other participants. The email address below is associated with your account and
                    used for logging in.
                  </p>
                  <Formik
                    initialValues={formikData}
                    enableReinitialize
                    validationSchema={Schema}
                    onSubmit={async (values, {setSubmitting}) => {
                      try {
                        const responseData: any = await ApiPut(
                          API_ROUTES.UserSettingsChange,
                          values
                        )

                        toast.success(responseData?.message)
                        setSubmitting(false)
                      } catch (error: any) {
                        toast.error(error?.error?.message)
                        setSubmitting(false)
                      }
                    }}
                  >
                    {({values, handleChange, handleBlur, handleSubmit, isSubmitting}) => (
                      <form onSubmit={handleSubmit}>
                        <div className='form-group'>
                          <input
                            type='text'
                            name='firstName'
                            placeholder=''
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.firstName}
                          />
                          <ErrorMessage name='firstName' component='div' className='red-error' />
                          <label>First Name</label>
                        </div>
                        <div className='form-group'>
                          <input
                            type='text'
                            name='lastName'
                            placeholder=''
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.lastName}
                          />
                          <ErrorMessage name='lastName' component='div' className='red-error' />
                          <label>Last Name</label>
                        </div>
                        <div className='form-group'>
                          <input
                            type='email'
                            name='email'
                            placeholder='Email'
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.email}
                          />
                          <ErrorMessage name='email' component='div' className='red-error' />
                          {/* <label>Email</label> */}
                        </div>

                        <Button isSubmitting={isSubmitting} />
                      </form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  )
}

export default Account
