export const User = 'user'
export const Login = 'login'
export const Signup = 'signup'
export const ForgotPassword = 'forgot-password'
export const ResetPassword = 'reset-password'
export const Setting = 'setting'
export const Home = 'home'
export const About = 'about'
export const Contact = 'contact'
export const Account = 'account'
export const Picture = 'picture'
export const Password = 'password'
export const TwoFactor = 'two-factor'
export const Timezone = 'timezone'
export const SystemNotifications = 'system-notifications'
export const AccountSettings = 'accountsettings'
export const MeetingMode = 'meeting-mode'
export const WaitingRoom = 'waiting-room'
export const HoldMusic = 'hold-music'
export const ChimesNameAnnounce = 'name-announce'
export const SMSNotifications = 'sms-notifications'
export const MeetingInvitations = 'meeting-invitations'
export const EmailNotifications = 'email-notifications'
export const ConfCall = 'conf/call'

export const Admin = 'admin'
export const AdminLogin = 'login'
export const AdminDashboard = 'dashboard'
export const AdminPlans = 'plans'
export const AdminUsers = 'users'
export const AdminPayments = 'payments'
export const AdminEmails = 'emails'
export const AdminPages = 'pages'
export const AdminSettings = 'settings'
export const AdminPassword = 'change-password'

export const Create = 'create'
export const Edit = 'edit'

export const routes = {
  HOME: `/conf/call/12/home`,
  DEVICE: `/conf/call/12/devices`,
  MEETING: `/conf/call/12/meeting`,
}
