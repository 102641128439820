import React from 'react'
import {Link, useNavigate} from 'react-router-dom'
import {Formik} from 'formik'
import * as Yup from 'yup'
import {toast} from 'react-toastify'
import {useAuth} from '../../../Hooks/useAuth'
import {useLocalStorage} from '../../../Hooks/useLocalStorage'
import {ApiPostNoAuth} from '../../../service/api'
import {API_ROUTES, ROUTES, STORAGE_KEY} from '../../../Constants'

const AdminLogin = () => {
  const intialData = {
    email: '',
    password: '',
  }
  const navigate = useNavigate()
  const {login} = useAuth()
  const {setItem} = useLocalStorage()

  const Schema = Yup.object().shape({
    email: Yup.string().email('Invalid email').required('Email is required'),
    password: Yup.string()
      .min(8, 'Password must be at least 8 characters')
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]+$/,
        'Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character'
      )
      .required('Password is required'),
  })

  return (
    <>
      <main>
        <section className='login-wrapper'>
          <div className='container'>
            <div className='logo'>
              <a href='!#'>
                <img src='/images/logo-new-scaled.jpg' alt='logo' height='auto' width='250' />
              </a>
            </div>
            <div className='login-form-box'>
              <h3>Admin Login</h3>
              <div className='row d-flex justify-content-center align-items-center h-50'>
                <Formik
                  initialValues={intialData}
                  validationSchema={Schema}
                  onSubmit={async (values, {setSubmitting}) => {
                    console.log('values', values)
                    try {
                      const loginData: any = await ApiPostNoAuth(API_ROUTES.LoginAdminUser, values)
                      setItem(STORAGE_KEY.Token, loginData?.data?.token)
                      login(loginData?.data)
                      navigate(`/${ROUTES.Admin}/${ROUTES.AdminDashboard}`)
                      toast.success(loginData?.message)
                      setSubmitting(false)
                    } catch (error: any) {
                      toast.error(error?.error?.message)
                      setSubmitting(false)
                    }
                  }}
                >
                  {({values, handleChange, handleBlur, handleSubmit, isSubmitting}) => (
                    <form onSubmit={handleSubmit}>
                      <div className='col-12 mt-2'>
                        <label>
                          Email<span className='text-danger'>*</span>
                        </label>
                        <input
                          id='email'
                          type='text'
                          name='email'
                          autoComplete='off'
                          className='form-control'
                          placeholder='Email'
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.email}
                        />
                      </div>

                      <div className='col-12 mt-3 relative'>
                        <label>
                          Password<span className='text-danger'>*</span>
                        </label>
                        <i className='far fa-eye control-icon'></i>
                        <input
                          id='password'
                          type='password'
                          name='password'
                          className='form-control pe-5'
                          placeholder='Password'
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.password}
                        />
                      </div>

                      <div className='col-12 mt-3 login-btn-wrapper'>
                        <div className='row align-items-center'>
                          <div className='col-6'>
                            <button
                              type='submit'
                              name='login'
                              value='login'
                              disabled={isSubmitting}
                              className='btn login-btn'
                            >
                              {isSubmitting ? (
                                <div
                                  className='spinner-border text-light'
                                  style={{
                                    height: '20px',
                                    width: '20px',
                                    verticalAlign: 'text-bottom',
                                  }}
                                  role='status'
                                >
                                  <span className='visually-hidden'>Loading...</span>
                                </div>
                              ) : (
                                <> Login </>
                              )}
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </section>
      </main>
      <footer>
        <div className='footer-logo-wrapper'>
          <h5>Powered by</h5>
          <img src='/images/aws.png' alt='aws logo' />
        </div>
      </footer>
    </>
  )
}

export default AdminLogin
