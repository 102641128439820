import {Formik} from 'formik'
import React, {useEffect, useState} from 'react'
import {Accordion} from 'react-bootstrap'
import Button from '../../Components/Button'
import {ApiPut} from '../../service/api'
import {API_ROUTES} from '../../Constants'
import {toast} from 'react-toastify'
import {UserSettings} from '../../Types'
import {getSettingData} from '../../common/api'

const MeetingMode = () => {
  const intialData = {
    selectedMode: '',
  }

  const [formikData, setFormikData] = useState(intialData)
  const abortController = new AbortController()

  function getModeNameIfNotSelected(meetingMode: any) {
    // Iterate over each mode in meetingMode
    for (let mode in meetingMode) {
      // Check if isSelected is false for the current mode
      if (meetingMode[mode]?.isSelected === true) {
        // Return the name of the mode if isSelected is false
        return mode
      }
    }
    // Return null if no mode with isSelected=false is found
    return null
  }

  const getData = async () => {
    const response: UserSettings = await getSettingData(abortController)
    setFormikData({
      selectedMode: getModeNameIfNotSelected(response?.meetingMode) ?? '',
    })
  }

  useEffect(() => {
    getData()

    return () => {
      abortController.abort()
    }
  }, [])

  console.log('formikData', formikData)
  return (
    <main>
      <section className='settings'>
        <div className='container-fluid px-0'>
          <div className='settings-wrapper'>
            <div className='settings-content' role='presentation'>
              <div className='bar' role='presentation'>
                <button type='button' className='btn button-light-primary'>
                  <span className='fa fa-chevron-left me-2' aria-hidden='true'></span>All settings
                </button>
              </div>
              <div className='panel panel--settings' aria-labelledby='contact_details_heading'>
                <div className='container' role='presentation'>
                  <h2 className='panel__header'>Meeting mode</h2>
                  <Formik
                    initialValues={formikData}
                    enableReinitialize
                    onSubmit={async (values, {setSubmitting}) => {
                      try {
                        const responseData: any = await ApiPut(API_ROUTES.UserSettingsChange, {
                          meetingMode: {
                            conversationCollaborationMode: {
                              content: '',
                              isSelected: values?.selectedMode === 'conversationCollaborationMode',
                            },
                            qAClassroomMode: {
                              content: '',
                              isSelected: values?.selectedMode === 'qAClassroomMode',
                            },
                            presentationWebinarMode: {
                              content: '',
                              isSelected: values?.selectedMode === 'presentationWebinarMode',
                            },
                            focusMode: {
                              content: '',
                              isSelected: values?.selectedMode === 'focusMode',
                            },
                          },
                        })

                        toast.success(responseData?.message)
                        setSubmitting(false)
                      } catch (error: any) {
                        toast.error(error?.error?.message)
                        setSubmitting(false)
                      }
                    }}
                  >
                    {({values, setFieldValue, handleSubmit, isSubmitting}) => (
                      <form onSubmit={handleSubmit}>
                        <Accordion
                          className='accordion mb-3'
                          activeKey={values?.selectedMode}
                          onSelect={(e) => {
                            setFieldValue('selectedMode', e)
                          }}
                        >
                          <Accordion.Item
                            className='accordion-item'
                            eventKey='conversationCollaborationMode'
                          >
                            <Accordion.Header className='accordion-header'>
                              <div className='d-flex'>
                                <svg
                                  xmlns='http://www.w3.org/2000/svg'
                                  viewBox='0 0 640 512'
                                  fill='#ebb03d'
                                  style={{
                                    minWidth: '33px',
                                    width: '33px',
                                  }}
                                >
                                  <path d='M208 352c114.9 0 208-78.8 208-176S322.9 0 208 0S0 78.8 0 176c0 38.6 14.7 74.3 39.6 103.4c-3.5 9.4-8.7 17.7-14.2 24.7c-4.8 6.2-9.7 11-13.3 14.3c-1.8 1.6-3.3 2.9-4.3 3.7c-.5 .4-.9 .7-1.1 .8l-.2 .2 0 0 0 0C1 327.2-1.4 334.4 .8 340.9S9.1 352 16 352c21.8 0 43.8-5.6 62.1-12.5c9.2-3.5 17.8-7.4 25.3-11.4C134.1 343.3 169.8 352 208 352zM448 176c0 112.3-99.1 196.9-216.5 207C255.8 457.4 336.4 512 432 512c38.2 0 73.9-8.7 104.7-23.9c7.5 4 16 7.9 25.2 11.4c18.3 6.9 40.3 12.5 62.1 12.5c6.9 0 13.1-4.5 15.2-11.1c2.1-6.6-.2-13.8-5.8-17.9l0 0 0 0-.2-.2c-.2-.2-.6-.4-1.1-.8c-1-.8-2.5-2-4.3-3.7c-3.6-3.3-8.5-8.1-13.3-14.3c-5.5-7-10.7-15.4-14.2-24.7c24.9-29 39.6-64.7 39.6-103.4c0-92.8-84.9-168.9-192.6-175.5c.4 5.1 .6 10.3 .6 15.5z' />
                                </svg>
                                <div className='ms-3'>
                                  <h5>Conversation/Collaboration mode</h5>
                                  <p className='mb-0'>
                                    {values?.selectedMode === 'conversationCollaborationMode' ? (
                                      <>
                                        Ideal for smaller groups. Team meetings, daily standups,
                                        interviews, or study groups.{' '}
                                        <b>
                                          All participants join the meeting unmuted and have full
                                          access to all sharing tools.
                                        </b>
                                      </>
                                    ) : (
                                      <>
                                        All participants join the meeting unmuted and have full
                                        access to all sharing tools
                                      </>
                                    )}
                                  </p>
                                </div>
                              </div>
                            </Accordion.Header>
                            <Accordion.Body className='accordion-body'>
                              <ul>
                                <p className='mb-1'>
                                  The following features are active in Conversation/Collaboration
                                  mode:
                                </p>
                                <ul className='list-style-type-circle'>
                                  <li>
                                    <p className='mb-2'>
                                      All participants can be heard, joining the meeting unmuted as
                                      default
                                    </p>
                                  </li>
                                  <li>
                                    <p className='mb-2'>All participants have full access to:</p>
                                  </li>
                                  <ul className='list-style-type-square mt-2'>
                                    <li>
                                      <p className='mb-2'>Activate their webcam</p>
                                    </li>
                                    <li>
                                      <p className='mb-2'>Share their screen</p>
                                    </li>
                                    <li>
                                      <p className='mb-2'>Upload/download a document</p>
                                    </li>
                                    <li>
                                      <p className='mb-2'>Present a document</p>
                                    </li>
                                    <li>
                                      <p className='mb-2'>Use the whiteboard/Annotation tool</p>
                                    </li>
                                    <li>
                                      <p className='mb-2'>Use text chat</p>
                                    </li>
                                  </ul>
                                </ul>
                              </ul>
                            </Accordion.Body>
                          </Accordion.Item>
                          <Accordion.Item className='accordion-item' eventKey='qAClassroomMode'>
                            <Accordion.Header className='accordion-header'>
                              <div className='d-flex'>
                                <svg
                                  xmlns='http://www.w3.org/2000/svg'
                                  style={{
                                    minWidth: '33px',
                                    width: '33px',
                                  }}
                                  viewBox='0 0 640 512'
                                  fill='#ebb03d'
                                >
                                  <path d='M208 352c114.9 0 208-78.8 208-176S322.9 0 208 0S0 78.8 0 176c0 38.6 14.7 74.3 39.6 103.4c-3.5 9.4-8.7 17.7-14.2 24.7c-4.8 6.2-9.7 11-13.3 14.3c-1.8 1.6-3.3 2.9-4.3 3.7c-.5 .4-.9 .7-1.1 .8l-.2 .2 0 0 0 0C1 327.2-1.4 334.4 .8 340.9S9.1 352 16 352c21.8 0 43.8-5.6 62.1-12.5c9.2-3.5 17.8-7.4 25.3-11.4C134.1 343.3 169.8 352 208 352zM448 176c0 112.3-99.1 196.9-216.5 207C255.8 457.4 336.4 512 432 512c38.2 0 73.9-8.7 104.7-23.9c7.5 4 16 7.9 25.2 11.4c18.3 6.9 40.3 12.5 62.1 12.5c6.9 0 13.1-4.5 15.2-11.1c2.1-6.6-.2-13.8-5.8-17.9l0 0 0 0-.2-.2c-.2-.2-.6-.4-1.1-.8c-1-.8-2.5-2-4.3-3.7c-3.6-3.3-8.5-8.1-13.3-14.3c-5.5-7-10.7-15.4-14.2-24.7c24.9-29 39.6-64.7 39.6-103.4c0-92.8-84.9-168.9-192.6-175.5c.4 5.1 .6 10.3 .6 15.5z' />
                                </svg>
                                <div className='ms-3'>
                                  <h5>Q & A/Classroom mode</h5>
                                  <p className='mb-0'>
                                    {values?.selectedMode === 'conversationCollaborationMode' ? (
                                      <>
                                        Ideal for online classes, prayer meetings, company meetings,
                                        or training sessions. Any small to mid-size groups where
                                        interaction is important, but only those you have chosen to
                                        present will be able to share any content.{' '}
                                        <b>
                                          {' '}
                                          All participants join the meeting muted, but can unmute
                                          themselves and have restricted access to all sharing
                                          tools.
                                        </b>
                                      </>
                                    ) : (
                                      <>
                                        All participants join the meeting muted, but can unmute
                                        themselves and have restricted access to all sharing tools
                                      </>
                                    )}
                                  </p>
                                </div>
                              </div>
                            </Accordion.Header>
                            <Accordion.Body className='accordion-body'>
                              <ul>
                                <p className='mb-1'>
                                  The following features are active in Q&A/Classroom mode:
                                </p>
                                <ul className='list-style-type-circle'>
                                  <li>
                                    <p className='mb-2'>
                                      All participants join the meeting muted as default & can
                                      unmute themselves
                                    </p>
                                  </li>
                                  <li>
                                    <p className='mb-2'>All participants have full access to:</p>
                                  </li>
                                  <ul className='list-style-type-square mt-2'>
                                    <li>
                                      <p className='mb-2'>Activate their webcam</p>
                                    </li>
                                    <li>
                                      <p className='mb-2'>Raise their hand</p>
                                    </li>
                                  </ul>
                                </ul>
                                <ul className='list-style-type-circle'>
                                  <li>
                                    <p className='mb-2'>ONLY moderators will be able to:</p>
                                  </li>
                                  <ul className='list-style-type-square mt-2'>
                                    <li>
                                      <p className='mb-2'>Share their screen</p>
                                    </li>
                                    <li>
                                      <p className='mb-2'>Upload/download/delete a document</p>
                                    </li>
                                    <li>
                                      <p className='mb-2'>
                                        Present and navigate through a document
                                      </p>
                                    </li>
                                    <li>
                                      <p className='mb-2'>Use the whiteboard/Annotation tool</p>
                                    </li>
                                    <li>
                                      <p className='mb-2'>Use text chat</p>
                                    </li>
                                  </ul>
                                </ul>
                              </ul>
                            </Accordion.Body>
                          </Accordion.Item>
                          <Accordion.Item
                            className='accordion-item'
                            eventKey='presentationWebinarMode'
                          >
                            <Accordion.Header className='accordion-header'>
                              <div className='d-flex'>
                                <svg
                                  xmlns='http://www.w3.org/2000/svg'
                                  style={{
                                    minWidth: '33px',
                                    width: '33px',
                                  }}
                                  viewBox='0 0 640 512'
                                  fill='#ebb03d'
                                >
                                  <path d='M208 352c114.9 0 208-78.8 208-176S322.9 0 208 0S0 78.8 0 176c0 38.6 14.7 74.3 39.6 103.4c-3.5 9.4-8.7 17.7-14.2 24.7c-4.8 6.2-9.7 11-13.3 14.3c-1.8 1.6-3.3 2.9-4.3 3.7c-.5 .4-.9 .7-1.1 .8l-.2 .2 0 0 0 0C1 327.2-1.4 334.4 .8 340.9S9.1 352 16 352c21.8 0 43.8-5.6 62.1-12.5c9.2-3.5 17.8-7.4 25.3-11.4C134.1 343.3 169.8 352 208 352zM448 176c0 112.3-99.1 196.9-216.5 207C255.8 457.4 336.4 512 432 512c38.2 0 73.9-8.7 104.7-23.9c7.5 4 16 7.9 25.2 11.4c18.3 6.9 40.3 12.5 62.1 12.5c6.9 0 13.1-4.5 15.2-11.1c2.1-6.6-.2-13.8-5.8-17.9l0 0 0 0-.2-.2c-.2-.2-.6-.4-1.1-.8c-1-.8-2.5-2-4.3-3.7c-3.6-3.3-8.5-8.1-13.3-14.3c-5.5-7-10.7-15.4-14.2-24.7c24.9-29 39.6-64.7 39.6-103.4c0-92.8-84.9-168.9-192.6-175.5c.4 5.1 .6 10.3 .6 15.5z' />
                                </svg>
                                <div className='ms-3'>
                                  <h5>Presentation/Webinar mode</h5>
                                  <p className='mb-0'>
                                    {values?.selectedMode === 'conversationCollaborationMode' ? (
                                      <>
                                        Ideal for any medium to large meeting,{' '}
                                        <b>
                                          where you and perhaps a few other presenters need to
                                          ensure viewers can not disrupt or interfere.
                                        </b>{' '}
                                        This is ideal for webinars, large company training sessions,
                                        seminars, or public assemblies. Easily switch to another
                                        mode or unmute individual participants at any point for
                                        questions or feedback from viewers.
                                      </>
                                    ) : (
                                      <>
                                        Presenter needs to ensure viewers cannot disrupt or
                                        interfere. Easily switch to another mode or unmute
                                        individual participants at any point for questions or
                                        feedback from viewers
                                      </>
                                    )}
                                  </p>
                                </div>
                              </div>
                            </Accordion.Header>
                            <Accordion.Body className='accordion-body'>
                              <ul>
                                <p className='mb-1'>
                                  The following features are active in Presentation/Webinar mode:
                                </p>
                                <ul className='list-style-type-circle'>
                                  <li>
                                    <p className='mb-2'>
                                      Waiting Room active - the meeting will not begin until the
                                      moderator joins
                                    </p>
                                  </li>
                                  <li>
                                    <p className='mb-2'>
                                      All participants join the meeting muted as default & can NOT
                                      unmute themselves
                                    </p>
                                  </li>
                                  <li>
                                    <p className='mb-2'>Name announce and chimes are off</p>
                                  </li>
                                  <li>
                                    <p className='mb-2'>ONLY moderators will be able to:</p>
                                  </li>
                                  <ul className='list-style-type-square mt-2'>
                                    <li>
                                      <p className='mb-2'>Activate their webcam</p>
                                    </li>
                                    <li>
                                      <p className='mb-2'>Share their screen</p>
                                    </li>
                                    <li>
                                      <p className='mb-2'>Upload/download a document</p>
                                    </li>
                                    <li>
                                      <p className='mb-2'>
                                        Present and navigate through a document
                                      </p>
                                    </li>
                                    <li>
                                      <p className='mb-2'>View full participant list</p>
                                    </li>
                                    <li>
                                      <p className='mb-2'>Use the whiteboard/Annotation tool</p>
                                    </li>
                                    <li>
                                      <p className='mb-2'>Use text chat</p>
                                    </li>
                                  </ul>
                                </ul>
                              </ul>
                            </Accordion.Body>
                          </Accordion.Item>
                          <Accordion.Item className='accordion-item' eventKey='focusMode'>
                            <Accordion.Header className='accordion-header'>
                              <div className='d-flex'>
                                <svg
                                  xmlns='http://www.w3.org/2000/svg'
                                  style={{
                                    minWidth: '33px',
                                    width: '33px',
                                  }}
                                  viewBox='0 0 640 512'
                                  fill='#ebb03d'
                                >
                                  <path d='M208 352c114.9 0 208-78.8 208-176S322.9 0 208 0S0 78.8 0 176c0 38.6 14.7 74.3 39.6 103.4c-3.5 9.4-8.7 17.7-14.2 24.7c-4.8 6.2-9.7 11-13.3 14.3c-1.8 1.6-3.3 2.9-4.3 3.7c-.5 .4-.9 .7-1.1 .8l-.2 .2 0 0 0 0C1 327.2-1.4 334.4 .8 340.9S9.1 352 16 352c21.8 0 43.8-5.6 62.1-12.5c9.2-3.5 17.8-7.4 25.3-11.4C134.1 343.3 169.8 352 208 352zM448 176c0 112.3-99.1 196.9-216.5 207C255.8 457.4 336.4 512 432 512c38.2 0 73.9-8.7 104.7-23.9c7.5 4 16 7.9 25.2 11.4c18.3 6.9 40.3 12.5 62.1 12.5c6.9 0 13.1-4.5 15.2-11.1c2.1-6.6-.2-13.8-5.8-17.9l0 0 0 0-.2-.2c-.2-.2-.6-.4-1.1-.8c-1-.8-2.5-2-4.3-3.7c-3.6-3.3-8.5-8.1-13.3-14.3c-5.5-7-10.7-15.4-14.2-24.7c24.9-29 39.6-64.7 39.6-103.4c0-92.8-84.9-168.9-192.6-175.5c.4 5.1 .6 10.3 .6 15.5z' />
                                </svg>
                                <div className='ms-3'>
                                  <h5>Focus mode</h5>
                                  <p className='mb-0'>
                                    {values?.selectedMode === 'conversationCollaborationMode' ? (
                                      <>
                                        Besides the organizer, all moderators, and any participants
                                        spotlighted by a moderator, participants in focus mode can
                                        still see their own video but only see other participants'
                                        names in the participant list and hear them when unmuted.
                                        Their video tiles are otherwise hidden.
                                      </>
                                    ) : (
                                      <>
                                        Prevent distraction from other participants and direct all
                                        attention to your presentation or training session.
                                      </>
                                    )}
                                  </p>
                                </div>
                              </div>
                            </Accordion.Header>
                            <Accordion.Body className='accordion-body'>
                              <ul>
                                <p className='mb-1'>
                                  The following features are active in Focus mode:
                                </p>
                                <ul className='list-style-type-circle'>
                                  <li>
                                    <p className='mb-2'>
                                      All participants join the meeting muted as default & can NOT
                                      unmute themselves
                                    </p>
                                  </li>
                                  <li>
                                    <p className='mb-2'>All participants have full access to:</p>
                                  </li>
                                  <ul className='list-style-type-square mt-2'>
                                    <li>
                                      <p className='mb-2'>Activate their webcam</p>
                                    </li>
                                    <li>
                                      <p className='mb-2'>Raise their hand</p>
                                    </li>
                                  </ul>
                                </ul>
                                <ul className='list-style-type-circle'>
                                  <li>
                                    <p className='mb-2'>ONLY moderators will be able to:</p>
                                  </li>
                                  <ul className='list-style-type-square mt-2'>
                                    <li>
                                      <p className='mb-2'>Share their screen</p>
                                    </li>
                                    <li>
                                      <p className='mb-2'>Upload/download/delete a document</p>
                                    </li>
                                    <li>
                                      <p className='mb-2'>
                                        Present and navigate through a document
                                      </p>
                                    </li>
                                    <li>
                                      <p className='mb-2'>Use the whiteboard/Annotation tool</p>
                                    </li>
                                  </ul>
                                </ul>
                                <ul className='list-style-type-circle'>
                                  <li>
                                    <p className='mb-2'>Chat mode: Chat with moderators only</p>
                                  </li>
                                </ul>
                              </ul>
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>

                        <Button isSubmitting={isSubmitting} />
                      </form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  )
}

export default MeetingMode
