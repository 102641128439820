import React from 'react'

const Timezone = () => {
  return (
    <main>
      <section className='settings'>
        <div className='container-fluid px-0'>
          <div className='settings-wrapper'>
            <div className='settings-content' role='presentation'>
              <div className='bar' role='presentation'>
                <button type='button' className='btn button-light-primary'>
                  <span className='fa fa-chevron-left me-2' aria-hidden='true'></span>All settings
                </button>
              </div>
              <div className='panel panel--settings' aria-labelledby='contact_details_heading'>
                <div className='container' role='presentation'>
                  <h2 className='panel__header'>Time zone</h2>
                  <p role='note'>
                    Your time zone is being automatically tracked based on the time on your computer
                    or phone. If your time zone is incorrect, please change your computer or phone
                    time zone settings.
                  </p>
                  <form>
                    <div
                      className='form-group two-factor__grey'
                      style={{
                        padding: '0px',
                        justifyContent: 'flex-start',
                        gap: '10px',
                        color: '#333',
                      }}
                    >
                      <span className='your-time-zone'>Your time zone</span>
                      <span>India Standard Time</span>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  )
}

export default Timezone
