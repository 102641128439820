// Copyright 2020-2021 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: MIT-0

import axios from 'axios'
import {API_ROUTES} from '../Constants'

export const BASE_URL = ''

export type MeetingFeatures = {
  Audio: {[key: string]: string}
}

export type CreateMeetingResponse = {
  MeetingFeatures: MeetingFeatures
  MediaRegion: string
}

export type JoinMeetingInfo = {
  Meeting: CreateMeetingResponse
  Attendee: string
}

interface GetAttendeeResponse {
  name: string
}

export async function createMeetingAndAttendee(
  id: string,
  attendeeName: string,
  region: string,
  echoReductionCapability = false
): Promise<any> {
  let attendee: any = {}
  let meeting: any = {}
  const meetingResponse = await axios.post(
    `${API_ROUTES.BASE_URL}/api/website/user/meetings/create-meeting`,
    {meetingId: id}
  )
  meeting = meetingResponse.data.data.Meeting

  // Create Attendee
  const attendeeResponse = await axios.post(
    `${API_ROUTES.BASE_URL}/api/website/user/meetings/create-attendee`,
    {
      meetingId: meeting.MeetingId,
      username: attendeeName,
    }
  )
  attendee = attendeeResponse.data.data.Attendee

  return {
    Meeting: meeting,
    Attendee: attendee,
  }
}

export async function getAttendee(title: string, attendeeId: string): Promise<GetAttendeeResponse> {
  const params = {
    title: encodeURIComponent(title),
    attendeeId: encodeURIComponent(attendeeId),
  }

  const res = await fetch(BASE_URL + 'attendee?' + new URLSearchParams(params), {
    method: 'GET',
  })

  if (!res.ok) {
    throw new Error('Invalid server response')
  }

  const data = await res.json()

  return {
    name: data.Name,
  }
}

export async function endMeeting(id: string): Promise<void> {
  try {
    await axios.post(`${API_ROUTES.BASE_URL}/api/website/user/meetings/end-meeting`, {
      meetingId: id,
    })
  } catch (error) {}
}

export const createGetAttendeeCallback =
  (meetingId: string) =>
  (chimeAttendeeId: string): Promise<GetAttendeeResponse> =>
    getAttendee(meetingId, chimeAttendeeId)

export const startTranscription = async (meetingId: string) => {
  try {
    await axios.post(
      `${API_ROUTES.BASE_URL}/api/website/user/meetings/start-meeting-transcription`,
      {
        meetingId: meetingId,
        languageCode: 'en-US',
        engine: 'transcribe',
        region: 'us-east-1',
        transcriptionStreamParams: {
          EnablePartialResultsStabilization: true,
          PartialResultsStability: 'low',
        },
      }
    )
  } catch (error) {}
}

export const endTranscription = async (meetingId: string) => {
  try {
    await axios.post(
      `${API_ROUTES.BASE_URL}/api/website/user/meetings/stop-meeting-transcription`,
      {
        meetingId: meetingId,
      }
    )
  } catch (error) {}
}

export const textTranslation = async (text: string, targetLanguageCode: string) => {
  try {
    const response = await axios.post(
      `${API_ROUTES.BASE_URL}/api/website/user/meetings/translate`,
      {
        text,
        sourceLanguageCode: 'en-US',
        targetLanguageCode,
      }
    )
    return response?.data?.data
  } catch (error) {}
}
