import React, {useEffect, useState} from 'react'
import {UserSettings} from '../../Types'
import {getSettingData} from '../../common/api'
import Button from '../../Components/Button'
import {Formik} from 'formik'
import {API_ROUTES} from '../../Constants'
import {toast} from 'react-toastify'
import {ApiPut} from '../../service/api'

const ChimesNameAnnounce = () => {
  const intialData = {
    enrtyExitChime: false,
    nameAnnounceOnEntry: false,
    nameAnnounceOnExit: false,
    announceNameOnlyToModerator: false,
  }
  const [formikData, setFormikData] = useState(intialData)
  const abortController = new AbortController()

  const getData = async () => {
    const response: UserSettings = await getSettingData(abortController)
    setFormikData({
      enrtyExitChime: response?.chimeNameAnnounce?.enrtyExitChime ?? false,
      nameAnnounceOnEntry: response?.chimeNameAnnounce?.nameAnnounceOnEntry ?? false,
      nameAnnounceOnExit: response?.chimeNameAnnounce?.nameAnnounceOnExit ?? false,
      announceNameOnlyToModerator:
        response?.chimeNameAnnounce?.announceNameOnlyToModerator ?? false,
    })
  }

  useEffect(() => {
    getData()

    return () => {
      abortController.abort()
    }
  }, [])
  return (
    <main>
      <section className='settings'>
        <div className='container-fluid px-0'>
          <div className='settings-wrapper'>
            <div className='settings-content' role='presentation'>
              <div className='bar' role='presentation'>
                <button type='button' className='btn button-light-primary'>
                  <span className='fa fa-chevron-left me-2' aria-hidden='true'></span>All settings
                </button>
              </div>
              <div className='panel panel--settings' aria-labelledby='contact_details_heading'>
                <div className='container' role='presentation'>
                  <h2 className='panel__header'>Chimes & name announce</h2>
                  <p role='note'>
                    In Collaboration and Classroom Mode, entry and exit chimes notify you when
                    participants join or leave by playing a short, non-disruptive tone. Name
                    announce prompts participants to record their name before entering so you know
                    exactly who has joined or left. These features are disabled automatically in
                    Webinar Mode.
                  </p>
                  <p>Toggle each of these features on and off using the switches below. </p>
                  <h4 className='mt-3 fw-normal'>Curated music playlist</h4>
                  <Formik
                    initialValues={formikData}
                    enableReinitialize
                    onSubmit={async (values, {setSubmitting}) => {
                      try {
                        const responseData: any = await ApiPut(API_ROUTES.UserSettingsChange, {
                          chimeNameAnnounce: values,
                        })

                        toast.success(responseData?.message)
                        setSubmitting(false)
                      } catch (error: any) {
                        toast.error(error?.error?.message)
                        setSubmitting(false)
                      }
                    }}
                  >
                    {({values, setFieldValue, handleSubmit, isSubmitting}) => (
                      <form onSubmit={handleSubmit}>
                        <div className='form-group two-factor__grey'>
                          <span>Entry/exit chimes</span>
                          <div className='form-check form-switch form-switch-lg'>
                            <input
                              className='form-check-input'
                              type='checkbox'
                              id='enrtyExitChime'
                              onChange={(e) => {
                                setFieldValue('enrtyExitChime', e.target.checked)
                              }}
                              checked={values.enrtyExitChime}
                            />
                            <label className='form-check-label' htmlFor='enrtyExitChime'></label>
                          </div>
                        </div>
                        <div className='form-group two-factor__grey'>
                          <span>Name announce on entry</span>

                          <div className='form-check form-switch form-switch-lg'>
                            <input
                              className='form-check-input'
                              type='checkbox'
                              id='nameAnnounceOnEntry'
                              onChange={(e) => {
                                setFieldValue('nameAnnounceOnEntry', e.target.checked)
                              }}
                              checked={values.nameAnnounceOnEntry}
                            />
                            <label
                              className='form-check-label'
                              htmlFor='nameAnnounceOnEntry'
                            ></label>
                          </div>
                        </div>
                        <div className='form-group two-factor__grey'>
                          <span>Name announce on exit (requires on entry above)</span>

                          <div className='form-check form-switch form-switch-lg'>
                            <input
                              className='form-check-input'
                              type='checkbox'
                              id='nameAnnounceOnExit'
                              onChange={(e) => {
                                setFieldValue('nameAnnounceOnExit', e.target.checked)
                              }}
                              checked={values.nameAnnounceOnExit}
                            />
                            <label
                              className='form-check-label'
                              htmlFor='nameAnnounceOnExit'
                            ></label>
                          </div>
                        </div>
                        <div className='form-group two-factor__grey'>
                          <span>Announce names only to moderator(s)</span>

                          <div className='form-check form-switch form-switch-lg'>
                            <input
                              className='form-check-input'
                              type='checkbox'
                              id='announceNameOnlyToModerator'
                              onChange={(e) => {
                                setFieldValue('announceNameOnlyToModerator', e.target.checked)
                              }}
                              checked={values.announceNameOnlyToModerator}
                            />
                            <label
                              className='form-check-label'
                              htmlFor='announceNameOnlyToModerator'
                            ></label>
                          </div>
                        </div>
                        <Button isSubmitting={isSubmitting} />
                      </form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  )
}

export default ChimesNameAnnounce
