import React, {useCallback, useEffect, useState} from 'react'
import {useDropzone} from 'react-dropzone'
import {ApiPut} from '../../service/api'
import {API_ROUTES} from '../../Constants'
import {UserSettings} from '../../Types'
import {getSettingData} from '../../common/api'

const Picture = () => {
  const onDrop = useCallback(async (acceptedFiles: File[]) => {
    var formData = new FormData()
    console.log('acceptedFiles', acceptedFiles)
    formData.append('profileImage', acceptedFiles[0])
    try {
      await ApiPut(API_ROUTES.UserProfileChange, formData, {
        'Content-Type': 'multipart/form-data',
      }).then(() => {
        getData()
      })
    } catch (error) {}
  }, [])

  const {getRootProps, getInputProps, open} = useDropzone({
    onDrop,
    noClick: true,
    noKeyboard: true,
    accept: {
      'image/png': ['.png', '.jpg', '.jpeg', '.gif'],
    },
    maxFiles: 1,
    multiple: false,
  })

  const [formikData, setFormikData] = useState('')
  const abortController = new AbortController()

  const getData = async () => {
    const response: UserSettings = await getSettingData(abortController)
    setFormikData(response?.profileImage ?? '')
  }

  useEffect(() => {
    getData()

    return () => {
      abortController.abort()
    }
  }, [])
  return (
    <main>
      <section className='settings'>
        <div className='container-fluid px-0'>
          <div className='settings-wrapper'>
            <div className='settings-content' role='presentation'>
              <div className='bar' role='presentation'>
                <button type='button' className='btn button-light-primary'>
                  <span className='fa fa-chevron-left me-2' aria-hidden='true'></span>All settings
                </button>
              </div>
              <div className='panel panel--settings' aria-labelledby='contact_details_heading'>
                <div className='container' role='presentation'>
                  <h2 className='panel__header'>Profile picture</h2>
                  <form className='image-form'>
                    <div className='image-form__preview direction-column-hand'>
                      <div className='avatar__container fs-exclude'>
                        {!!formikData ? (
                          <img
                            className='avatar avatar--medium avatar--circle'
                            src={formikData}
                            alt='image'
                          />
                        ) : (
                          <div className='avatar avatar--medium avatar--circle'>
                            <svg
                              xmlns='http://www.w3.org/2000/svg'
                              fill='#666'
                              className='mb-3'
                              viewBox='0 0 512 512'
                            >
                              <path d='M0 96C0 60.7 28.7 32 64 32H448c35.3 0 64 28.7 64 64V416c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V96zM323.8 202.5c-4.5-6.6-11.9-10.5-19.8-10.5s-15.4 3.9-19.8 10.5l-87 127.6L170.7 297c-4.6-5.7-11.5-9-18.7-9s-14.2 3.3-18.7 9l-64 80c-5.8 7.2-6.9 17.1-2.9 25.4s12.4 13.6 21.6 13.6h96 32H424c8.9 0 17.1-4.9 21.2-12.8s3.6-17.4-1.4-24.7l-120-176zM112 192a48 48 0 1 0 0-96 48 48 0 1 0 0 96z' />
                            </svg>
                          </div>
                        )}
                      </div>
                    </div>
                    <div {...getRootProps({className: 'dropzone'})}>
                      <input {...getInputProps()} />
                      <div
                        className='form__drag-drop image-form__drag-drop clickable relative'
                        id='dropzone'
                        aria-disabled='false'
                      >
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          fill='#666'
                          className='mb-3'
                          viewBox='0 0 512 512'
                        >
                          <path d='M0 96C0 60.7 28.7 32 64 32H448c35.3 0 64 28.7 64 64V416c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V96zM323.8 202.5c-4.5-6.6-11.9-10.5-19.8-10.5s-15.4 3.9-19.8 10.5l-87 127.6L170.7 297c-4.6-5.7-11.5-9-18.7-9s-14.2 3.3-18.7 9l-64 80c-5.8 7.2-6.9 17.1-2.9 25.4s12.4 13.6 21.6 13.6h96 32H424c8.9 0 17.1-4.9 21.2-12.8s3.6-17.4-1.4-24.7l-120-176zM112 192a48 48 0 1 0 0-96 48 48 0 1 0 0 96z' />
                        </svg>

                        <p className='text-large'>Drop picture here</p>
                        <p className='text-light'>
                          Accepts JPG, PNG, or GIF
                          <br />
                          It's best if the picture is the same length and height
                        </p>
                        <p className='last'>
                          <button type='button' className='btn btn-primary' onClick={open}>
                            <span>Select file</span>
                          </button>
                        </p>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  )
}

export default Picture
