// Copyright 2020-2021 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: MIT-0

import React, {useEffect, useState} from 'react'

import MeetingRoster from '../MeetingRoster'
import Navigation from '.'
import {useNavigation} from '../../providers/NavigationProvider'
import {
  Flex,
  Roster,
  RosterCell,
  RosterGroup,
  RosterHeader,
  useAudioVideo,
} from 'amazon-chime-sdk-component-library-react'
import Chat from '../Chat'
import {
  Attendee,
  Transcript,
  TranscriptEvent,
  TranscriptItemType,
  TranscriptResult,
} from 'amazon-chime-sdk-js'
import {useTranscript} from '../../providers/TranscriptProvider'

const MeetingTranscript = () => {
  const {transcripts} = useTranscript()

  console.log('transcriptstranscriptstranscripts', transcripts)
  return (
    <RosterGroup>
      {transcripts?.map((transcript: any, index: number) => (
        <RosterCell
          key={index}
          name={transcript?.speaker?.split('_')?.[0]}
          subtitle={transcript?.text}
        />
      ))}
    </RosterGroup>
  )
}

const NavigationControl = () => {
  const {showNavbar, showRoster, showChat, showTranscript, toggleTranscript} = useNavigation()
  const {addTranscript, targetLanguageCode} = useTranscript()
  const audioVideo = useAudioVideo()
  useEffect(() => {
    if (audioVideo) {
      audioVideo.transcriptionController?.subscribeToTranscriptEvent(
        (transcriptEvent: TranscriptEvent) => {
          addTranscript(transcriptEvent)
        }
      )
    }
  }, [audioVideo])

  const view = () => {
    if (showRoster && showChat) {
      return (
        <Flex layout='stack' style={{height: '100vh'}}>
          <MeetingRoster />
          <Chat />
        </Flex>
      )
    }
    if (showRoster) {
      return <MeetingRoster />
    }
    if (showChat) {
      return <Chat />
    }
    if (showTranscript) {
      return (
        <Roster className='roster-transcript'>
          <RosterHeader onClose={toggleTranscript} title='Transcript' />
          <RosterGroup>
            <MeetingTranscript />
          </RosterGroup>
        </Roster>
      )
    }
    return null
  }

  return (
    <>
      {showNavbar ? <Navigation /> : null}
      {view()}
    </>
  )
}

export default NavigationControl
