import React, {useEffect, useState} from 'react'
import {UserSettings} from '../../Types'
import {getSettingData} from '../../common/api'
import {Formik} from 'formik'
import {API_ROUTES} from '../../Constants'
import {ApiPut} from '../../service/api'
import {toast} from 'react-toastify'
import Button from '../../Components/Button'

const Twofactor = () => {
  const intialData = {
    twoFactorAuth: false,
  }
  const [formikData, setFormikData] = useState(intialData)
  const abortController = new AbortController()

  const getData = async () => {
    const response: UserSettings = await getSettingData(abortController)
    setFormikData({
      twoFactorAuth: response.twoFactorAuth ?? false,
    })
  }

  useEffect(() => {
    getData()

    return () => {
      abortController.abort()
    }
  }, [])
  return (
    <main>
      <section className='settings'>
        <div className='container-fluid px-0'>
          <div className='settings-wrapper'>
            <div className='settings-content' role='presentation'>
              <div className='bar' role='presentation'>
                <button type='button' className='btn button-light-primary'>
                  <span className='fa fa-chevron-left me-2' aria-hidden='true'></span>All settings
                </button>
              </div>
              <div className='panel panel--settings' aria-labelledby='contact_details_heading'>
                <div className='container' role='presentation'>
                  <h2 className='panel__header'>Two-factor authentication</h2>
                  <p role='note'>
                    Two-factor authentication gives you additional security by requiring a
                    verification code whenever you sign{' '}
                  </p>
                  <Formik
                    initialValues={formikData}
                    enableReinitialize
                    onSubmit={async (values, {setSubmitting}) => {
                      try {
                        const responseData: any = await ApiPut(
                          API_ROUTES.UserSettingsChange,
                          values
                        )

                        toast.success(responseData?.message)
                        setSubmitting(false)
                      } catch (error: any) {
                        toast.error(error?.error?.message)
                        setSubmitting(false)
                      }
                    }}
                  >
                    {({values, setFieldValue, handleSubmit, isSubmitting}) => (
                      <form onSubmit={handleSubmit}>
                        <div className='form-group two-factor__grey'>
                          <span>Enable two-factor authentication</span>
                          <div className='form-check form-switch form-switch-lg'>
                            <input
                              className='form-check-input'
                              type='checkbox'
                              id='sms-notification'
                              onChange={(e) => {
                                setFieldValue('twoFactorAuth', e.target.checked)
                              }}
                              checked={values.twoFactorAuth}
                            />
                            <label className='form-check-label' htmlFor='sms-notification'></label>
                          </div>
                        </div>

                        <Button isSubmitting={isSubmitting} />
                      </form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  )
}

export default Twofactor
