import React from 'react'
import {Link, Outlet, useNavigate} from 'react-router-dom'
import {ROUTES} from '../../Constants'
import {useAuth} from '../../Hooks/useAuth'

const AdminSidebar = () => {
  const navigate = useNavigate()
  const {login} = useAuth()
  const handleLogout = () => {
    localStorage.clear()
    login(null)
    navigate(`/${ROUTES.Admin}/${ROUTES.AdminLogin}`)
  }
  return (
    <main>
      <div className='admin-panel'>
        <nav className='navbar navbar-expand-lg navbar-light bg-light'>
          <a className='navbar-brand' href='#'>
            <div className='center-image'>
              <img src='/images/logo-new-scaled.jpg' alt='logo' height='auto' width='150' />
            </div>
          </a>
        </nav>
        <div className='wrapper admin-wrapper d-flex align-items-stretch'>
          <nav id='sidebar'>
            <ul className='list-unstyled components mb-5'>
              <li>
                <Link to={`/${ROUTES.Admin}/${ROUTES.AdminDashboard}`}>
                  <span className='fa fa-home me-3'></span>{' '}
                  <span className='desktop-none'>Dashboard</span>
                </Link>
              </li>
              <li>
                <Link to={`/${ROUTES.Admin}/${ROUTES.AdminPlans}`}>
                  <span className='fa fa-plus me-3'></span>{' '}
                  <span className='desktop-none'>Plans</span>
                </Link>
              </li>
              <li>
                <Link to={`/${ROUTES.Admin}/${ROUTES.AdminUsers}`}>
                  <span className='fa fa-user me-3'></span>{' '}
                  <span className='desktop-none'>Users</span>
                </Link>
              </li>
              <li>
                <Link to={`/${ROUTES.Admin}/${ROUTES.AdminPayments}`}>
                  <span className='fa fa-credit-card-alt me-3'></span>{' '}
                  <span className='desktop-none'>Payments</span>
                </Link>
              </li>
              <li>
                <Link to={`/${ROUTES.Admin}/${ROUTES.AdminEmails}`}>
                  <span className='fa fa-envelope me-3'></span>{' '}
                  <span className='desktop-none'>Email Templates</span>
                </Link>
              </li>
              <li>
                <Link to={`/${ROUTES.Admin}/${ROUTES.AdminPages}`}>
                  <span className='fa fa-file-text me-3'></span>{' '}
                  <span className='desktop-none'>Pages</span>
                </Link>
              </li>
              <li>
                <Link to={`/${ROUTES.Admin}/${ROUTES.AdminSettings}`}>
                  <span className='fa fa-wrench me-3'></span>{' '}
                  <span className='desktop-none'>Settings</span>
                </Link>
              </li>
              <li>
                <Link to={`/${ROUTES.Admin}/${ROUTES.AdminPassword}`}>
                  <span className='fa fa-unlock-alt me-3'></span>{' '}
                  <span className='desktop-none'>Change Password</span>
                </Link>
              </li>
              <li>
                <a onClick={handleLogout}>
                  <span className='fa fa-sign-out me-3'></span>{' '}
                  <span className='desktop-none'>Logout</span>
                </a>
              </li>
            </ul>
          </nav>
          <Outlet />
        </div>
      </div>
    </main>
  )
}

export default AdminSidebar
