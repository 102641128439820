import {Outlet, Navigate} from 'react-router-dom'
import {KEYWORDS, ROUTES} from '../Constants'
import {useAuth} from '../Hooks/useAuth'

const PublicRoutes = () => {
  const {user} = useAuth()
  if (user?.role?.name === KEYWORDS.Roles.SuperAdmin) {
    return user?.token ? <Navigate to={`/${ROUTES.Admin}/${ROUTES.AdminDashboard}`} /> : <Outlet />
  }
  return user?.token ? <Navigate to={`/${ROUTES.User}/${ROUTES.Home}`} /> : <Outlet />
}

export default PublicRoutes
