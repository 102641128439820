import React, {useState} from 'react'
import {useNavigate} from 'react-router-dom'
import {
  ControlBarButton,
  Phone,
  Modal,
  ModalBody,
  ModalHeader,
  ModalButton,
  ModalButtonGroup,
  useLogger,
} from 'amazon-chime-sdk-component-library-react'

import {StyledP} from './Styled'
import {useAppState} from '../../providers/AppStateProvider'
import {routes} from '../../../../Constants/routes'
import {endMeeting} from '../../../../Utils/api'

const EndMeetingControl: React.FC = () => {
  const logger = useLogger()
  const [showModal, setShowModal] = useState(false)
  const toggleModal = (): void => setShowModal(!showModal)
  const {meetingId} = useAppState()
  const navigate = useNavigate()

  const leaveMeeting = async (): Promise<void> => {
    navigate(routes.HOME)
  }

  const endMeetingForAll = async (): Promise<void> => {
    try {
      if (meetingId) {
        await endMeeting(meetingId)
        navigate(routes.HOME)
      }
    } catch (e) {
      logger.error(`Could not end meeting: ${e}`)
    }
  }

  return (
    <>
      <ControlBarButton icon={<Phone />} onClick={toggleModal} label='Leave' />
      {showModal && (
        <Modal size='md' onClose={toggleModal} rootId='modal-root'>
          <ModalHeader title='End Meeting' />
          <ModalBody>
            <StyledP>
              Leave meeting or you can end the meeting for all. The meeting cannot be used once it
              ends.
            </StyledP>
          </ModalBody>
          <ModalButtonGroup
            primaryButtons={[
              <ModalButton
                key='end-meeting-for-all'
                onClick={endMeetingForAll}
                variant='primary'
                label='End meeting for all'
                closesModal
              />,
              <ModalButton
                key='leave-meeting'
                onClick={leaveMeeting}
                variant='primary'
                label='Leave Meeting'
                closesModal
              />,
              <ModalButton
                key='cancel-meeting-ending'
                variant='secondary'
                label='Cancel'
                closesModal
              />,
            ]}
          />
        </Modal>
      )}
    </>
  )
}

export default EndMeetingControl
