import React, {useEffect, useState} from 'react'
import {Link, useNavigate, useParams} from 'react-router-dom'
import {API_ROUTES, ROUTES} from '../../../Constants'
import {ErrorMessage, Formik} from 'formik'
import {toast} from 'react-toastify'
import * as Yup from 'yup'
import {ApiGet, ApiPost, ApiPut} from '../../../service/api'
import Button from '../../../Components/Button'

const ModifyUsers = () => {
  const {id} = useParams()
  const intialData = {
    email: '',
    mobileNo: '',
    firstName: '',
    lastName: '',
    planId: '',
    companyName: '',
    role: '',
  }
  const navigate = useNavigate()
  const [formikData, setFormikData] = useState(intialData)
  const [roles, setRoles] = useState([])
  const [plans, setPlans] = useState([])

  const Schema = Yup.object().shape({
    companyName: Yup.string().required('Companyname is required'),
    firstName: Yup.string().required('Firstname is required'),
    lastName: Yup.string().required('Lastname is required'),
    email: Yup.string().email('Invalid email').required('Email is required'),
    mobileNo: Yup.string()
      .matches(/^\d+$/, 'Mobileno must contain numbers only')
      .required('Mobileno is required'),
    planId: Yup.string().required('Plan is required'),
    role: Yup.string().required('Role is required'),
  })

  const getData = async () => {
    try {
      const response: any = await ApiGet(`${API_ROUTES.AdminUser}/${id}`)
      setFormikData({
        email: response?.data?.email,
        mobileNo: response?.data?.mobileNo,
        firstName: response?.data?.firstName,
        lastName: response?.data?.lastName,
        planId: response?.data?.planId,
        companyName: response?.data?.companyName,
        role: response?.data?.role,
      })
    } catch (error) {
      console.log(error)
    }
  }

  const getRolesData = async () => {
    try {
      const rolesData: any = await ApiGet(API_ROUTES.Role)
      setRoles(rolesData?.data?.docs ?? [])
    } catch (error) {}
  }

  const getPlanData = async () => {
    try {
      const planData: any = await ApiGet(API_ROUTES.Plan)
      setPlans(planData?.data?.docs ?? [])
    } catch (error) {}
  }

  useEffect(() => {
    if (id) getData()
    getRolesData()
    getPlanData()
  }, [])

  return (
    <div id='content' className='p-3 pt-1'>
      <div className='container '>
        <div className='input-group mb-3 justify-content-end'>
          <a href='#' className='btn btn-primary'>
            Back
          </a>
        </div>
        <div className='card'>
          <div className='card-header'>
            <h2 className='mb-0'>{id ? 'Edit' : 'Create '} User </h2>
          </div>
          <div className='card-body'>
            <Formik
              initialValues={formikData}
              enableReinitialize
              validationSchema={Schema}
              onSubmit={async (values, {setSubmitting}) => {
                try {
                  if (id) {
                    const responseData: any = await ApiPut(`${API_ROUTES.AdminUser}/${id}`, values)
                    toast.success(responseData?.message)
                  } else {
                    const responseData: any = await ApiPost(API_ROUTES.AdminUser, values)
                    toast.success(responseData?.message)
                  }
                  navigate(`/${ROUTES.Admin}/${ROUTES.AdminUsers}`)
                  setSubmitting(false)
                } catch (error: any) {
                  toast.error(error?.error?.message)
                  setSubmitting(false)
                }
              }}
            >
              {({values, handleChange, handleBlur, handleSubmit, isSubmitting, errors}) => (
                <form onSubmit={handleSubmit}>
                  <div className='form-group'>
                    <label htmlFor='name'>Email:</label>
                    <input
                      type='email'
                      className='form-control elegant-input'
                      id='email'
                      name='email'
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email}
                    />
                    <ErrorMessage name='email' component='div' className='red-error' />
                  </div>

                  <div className='form-group'>
                    <label htmlFor='firstName'>First Name:</label>
                    <input
                      type='text'
                      className='form-control elegant-input'
                      id='firstName'
                      name='firstName'
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.firstName}
                    />
                    <ErrorMessage name='firstName' component='div' className='red-error' />
                  </div>

                  <div className='form-group'>
                    <label htmlFor='lastName'>Last Name:</label>
                    <input
                      type='text'
                      className='form-control elegant-input'
                      id='lastName'
                      name='lastName'
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.lastName}
                    />
                    <ErrorMessage name='lastName' component='div' className='red-error' />
                  </div>

                  <div className='form-group'>
                    <label htmlFor='companyName'>Company Name:</label>
                    <input
                      type='text'
                      className='form-control elegant-input'
                      id='companyName'
                      name='companyName'
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.companyName}
                    />
                    <ErrorMessage name='companyName' component='div' className='red-error' />
                  </div>

                  {/* <div className='form-group'>
                    <label htmlFor='price'>Password:</label>
                    <input
                      type='password'
                      className='form-control elegant-input'
                      id='Password'
                      name='Password'
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.subject}
                    />
                    <ErrorMessage name='subject' component='div' className='red-error' />
                  </div> */}

                  <div className='form-group'>
                    <label htmlFor='mobileNo'>Mobile Number:</label>
                    <input
                      type='text'
                      className='form-control elegant-input'
                      id='mobileNo'
                      name='mobileNo'
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.mobileNo}
                    />
                    <ErrorMessage name='mobileNo' component='div' className='red-error' />
                  </div>

                  <div className='form-group'>
                    <label htmlFor='role'>Role:</label>
                    <select
                      className='form-control elegant-input'
                      name='role'
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.role}
                    >
                      <option value=''>Select Role</option>
                      {roles?.map((role: {_id: string; name: string}) => (
                        <option value={role?._id}>{role?.name}</option>
                      ))}
                    </select>
                    <ErrorMessage name='role' component='div' className='red-error' />
                  </div>

                  <div className='form-group'>
                    <label htmlFor='planId'>Plans:</label>
                    <select
                      className='form-control elegant-input'
                      name='planId'
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.planId}
                    >
                      <option value=''>Select Plans</option>
                      {plans?.map((plan: {_id: string; planName: string}) => (
                        <option value={plan?._id}>{plan?.planName}</option>
                      ))}
                    </select>
                    <ErrorMessage name='planId' component='div' className='red-error' />
                  </div>

                  <Button isSubmitting={isSubmitting} text={id ? 'Update' : 'Add'} />
                </form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ModifyUsers
