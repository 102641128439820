import React from 'react'
import {BrowserRouter} from 'react-router-dom'
import RoutesData from './routes'
import {AuthProvider} from './Context/AuthContext'
import {ToastContainer} from 'react-toastify'

function App() {
  return (
    <AuthProvider>
      {/* <BrowserRouter basename='/~nowvideo/FE_nowvideo'> */}
      <BrowserRouter basename='/'>
        <RoutesData />
      </BrowserRouter>
      <ToastContainer
        position='top-right'
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme='colored'
      />
    </AuthProvider>
  )
}

export default App
