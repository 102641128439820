import React from 'react'
import {
  ControlBar,
  AudioInputVFControl,
  AudioInputControl,
  ContentShareControl,
  AudioOutputControl,
  ControlBarButton,
  useUserActivityState,
  Dots,
  VideoInputControl,
  Feedback,
  Label,
  Flex,
} from 'amazon-chime-sdk-component-library-react'

import EndMeetingControl from '../EndMeetingControl'
import {useNavigation} from '../../providers/NavigationProvider'
import {StyledControls} from './Styled'
import {useAppState} from '../../providers/AppStateProvider'
import {VideoFiltersCpuUtilization} from '../../../../Types/chimeTypes'
import VideoInputTransformControl from '../../../../Components/MeetingControls/VideoInputTransformControl'
import {useTranscript} from '../../providers/TranscriptProvider'

const MeetingControls: React.FC = () => {
  const {toggleNavbar, closeRoster, showRoster, startTranscript, isTranscriptStart, endTranscript} =
    useNavigation()
  const {selectedLanguage, targetLanguageCode} = useTranscript()
  const {isUserActive} = useUserActivityState()
  const {isWebAudioEnabled, videoTransformCpuUtilization} = useAppState()
  const videoTransformsEnabled =
    videoTransformCpuUtilization !== VideoFiltersCpuUtilization.Disabled

  const handleToggle = (): void => {
    if (showRoster) {
      closeRoster()
    }
    toggleNavbar()
  }

  const handleTranscribe = (): void => {
    if (isTranscriptStart) {
      endTranscript()
    } else {
      startTranscript()
    }
  }

  return (
    <StyledControls className='controls' active={!!isUserActive}>
      <ControlBar className='controls-menu' layout='undocked-horizontal' showLabels>
        <ControlBarButton
          className='mobile-toggle'
          icon={<Dots />}
          onClick={handleToggle}
          label='Menu'
        />
        <Flex
          layout='fill-space-centered'
          flexDirection='column'
          className='form-group m-3 gap-2 '
          style={{
            cursor: 'pointer',
          }}
        >
          <span>Translation</span>
          <select
            id='targetLanguageCode'
            className='form-select valid'
            name='targetLanguageCode'
            value={targetLanguageCode}
            onChange={(e) => {
              selectedLanguage(e.target.value)
            }}
            style={{
              width: '200px',
              borderRadius: '20px',
            }}
          >
            <option value='ab-GE'>Abkhaz</option>
            <option value='af-ZA'>Afrikaans</option>
            <option value='ar-AE'>Arabic, Gulf</option>
            <option value='ar-SA'>Arabic, Modern Standard</option>
            <option value='hy-AM'>Armenian</option>
            <option value='ast-ES'>Asturian</option>
            <option value='az-AZ'>Azerbaijani</option>
            <option value='ba-RU'>Bashkir</option>
            <option value='eu-ES'>Basque</option>
            <option value='be-BY'>Belarusian</option>
            <option value='bn-IN'>Bengali</option>
            <option value='bs-BA'>Bosnian</option>
            <option value='bg-BG'>Bulgarian</option>
            <option value='ca-ES'>Catalan</option>
            <option value='ckb-IR'>Central Kurdish, Iran</option>
            <option value='ckb-IQ'>Central Kurdish, Iraq</option>
            <option value='zh-CN'>Chinese, Simplified</option>
            <option value='zh-TW'>Chinese, Traditional</option>
            <option value='hr-HR'>Croatian</option>
            <option value='cs-CZ'>Czech</option>
            <option value='da-DK'>Danish</option>
            <option value='nl-NL'>Dutch</option>
            <option value='en-AU'>English, Australian</option>
            <option value='en-GB'>English, British</option>
            <option value='en-IN'>English, Indian</option>
            <option value='en-IE'>English, Irish</option>
            <option value='en-NZ'>English, New Zealand</option>
            <option value='en-AB'>English, Scottish</option>
            <option value='en-ZA'>English, South African</option>
            <option value='en-US'>English, US</option>
            <option value='en-WL'>English, Welsh</option>
            <option value='et-ET'>Estonian</option>
            <option value='fa-IR'>Farsi</option>
            <option value='fi-FI'>Finnish</option>
            <option value='fr-FR'>French</option>
            <option value='fr-CA'>French, Canadian</option>
            <option value='gl-ES'>Galician</option>
            <option value='ka-GE'>Georgian</option>
            <option value='de-DE'>German</option>
            <option value='de-CH'>German, Swiss</option>
            <option value='el-GR'>Greek</option>
            <option value='gu-IN'>Gujarati</option>
            <option value='ha-NG'>Hausa</option>
            <option value='he-IL'>Hebrew</option>
            <option value='hi-IN'>Hindi, Indian</option>
            <option value='hu-HU'>Hungarian</option>
            <option value='is-IS'>Icelandic</option>
            <option value='id-ID'>Indonesian</option>
            <option value='it-IT'>Italian</option>
            <option value='ja-JP'>Japanese</option>
            <option value='kab-DZ'>Kabyle</option>
            <option value='kn-IN'>Kannada</option>
            <option value='kk-KZ'>Kazakh</option>
            <option value='rw-RW'>Kinyarwanda</option>
            <option value='ko-KR'>Korean</option>
            <option value='ky-KG'>Kyrgyz</option>
            <option value='lv-LV'>Latvian</option>
            <option value='lt-LT'>Lithuanian</option>
            <option value='lg-IN'>Luganda</option>
            <option value='mk-MK'>Macedonian</option>
            <option value='ms-MY'>Malay</option>
            <option value='ml-IN'>Malayalam</option>
            <option value='mt-MT'>Maltese</option>
            <option value='mr-IN'>Marathi</option>
            <option value='mhr-RU'>Meadow Mari</option>
            <option value='mn-MN'>Mongolian</option>
            <option value='no-NO'>Norwegian Bokmål</option>
            <option value='or-IN'>Odia/Oriya</option>
            <option value='ps-AF'>Pashto</option>
            <option value='pl-PL'>Polish</option>
            <option value='pt-PT'>Portuguese</option>
            <option value='pt-BR'>Portuguese, Brazilian</option>
            <option value='pa-IN'>Punjabi</option>
            <option value='ro-RO'>Romanian</option>
            <option value='ru-RU'>Russian</option>
            <option value='sr-RS'>Serbian</option>
            <option value='si-LK'>Sinhala</option>
            <option value='sk-SK'>Slovak</option>
            <option value='sl-SI'>Slovenian</option>
            <option value='so-SO'>Somali</option>
            <option value='es-ES'>Spanish</option>
            <option value='es-US'>Spanish, US</option>
            <option value='su-ID'>Sundanese</option>
            <option value='sw-KE'>Swahili, Kenya</option>
            <option value='sw-BI'>Swahili, Burundi</option>
            <option value='sw-RW'>Swahili, Rwanda</option>
            <option value='sw-TZ'>Swahili, Tanzania</option>
            <option value='sw-UG'>Swahili, Uganda</option>
            <option value='sv-SE'>Swedish</option>
            <option value='tl-PH'>Tagalog/Filipino</option>
            <option value='ta-IN'>Tamil</option>
            <option value='tt-RU'>Tatar</option>
            <option value='te-IN'>Telugu</option>
            <option value='th-TH'>Thai</option>
            <option value='tr-TR'>Turkish</option>
            <option value='uk-UA'>Ukrainian</option>
            <option value='ug-CN'>Uyghur</option>
            <option value='uz-UZ'>Uzbek</option>
            <option value='vi-VN'>Vietnamese</option>
            <option value='cy-WL'>Welsh</option>
            <option value='wo-SN'>Wolof</option>
            <option value='zu-ZA'>Zulu</option>
          </select>
        </Flex>
        <Flex
          layout='fill-space-centered'
          flexDirection='column'
          style={{
            cursor: 'pointer',
          }}
        >
          <Feedback width='2rem' onClick={handleTranscribe} />
          <Label
            style={{
              cursor: 'pointer',
              whiteSpace: 'nowrap',
            }}
            onClick={handleTranscribe}
          >
            {isTranscriptStart ? 'End' : 'Start'} Transcribe
          </Label>
        </Flex>
        {isWebAudioEnabled ? <AudioInputVFControl /> : <AudioInputControl />}
        {videoTransformsEnabled ? <VideoInputTransformControl /> : <VideoInputControl />}
        <ContentShareControl />
        <AudioOutputControl />
        <EndMeetingControl />
      </ControlBar>
    </StyledControls>
  )
}

export default MeetingControls
