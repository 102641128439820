import React, {useEffect, useState} from 'react'
import {API_ROUTES} from '../../Constants'
import {toast} from 'react-toastify'
import {ApiPut} from '../../service/api'
import {Formik} from 'formik'
import {UserSettings} from '../../Types'
import {getSettingData} from '../../common/api'
import Button from '../../Components/Button'

const SystemNotifications = () => {
  const intialData = {
    notificationFlag: false,
  }
  const [formikData, setFormikData] = useState(intialData)
  const abortController = new AbortController()

  const getData = async () => {
    const response: UserSettings = await getSettingData(abortController)
    setFormikData({
      notificationFlag: response.notificationFlag ?? false,
    })
  }

  useEffect(() => {
    getData()

    return () => {
      abortController.abort()
    }
  }, [])
  return (
    <main>
      <section className='settings'>
        <div className='container-fluid px-0'>
          <div className='settings-wrapper'>
            <div className='settings-content' role='presentation'>
              <div className='bar' role='presentation'>
                <button type='button' className='btn button-light-primary'>
                  <span className='fa fa-chevron-left me-2' aria-hidden='true'></span>All settings
                </button>
              </div>
              <div className='panel panel--settings' aria-labelledby='contact_details_heading'>
                <div className='container' role='presentation'>
                  <h2 className='panel__header'>System notifications</h2>
                  <p role='note'>
                    Notifications keep you informed of the things you care about. Customize your
                    desktop and mobile notifications below.
                  </p>
                  <div className='system__notify'>
                    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'>
                      <path d='M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z' />
                    </svg>
                    <p>Notification Supported - the device can show system notifications.</p>
                  </div>
                  <div className='system__notify mt-3'>
                    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'>
                      <path d='M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z' />
                    </svg>
                    <p>
                      Push Notification Supported - the device can receive asynchronous
                      notifications and updates.
                    </p>
                  </div>
                  <div className='system__notify mt-3'>
                    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'>
                      <path d='M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z' />
                    </svg>
                    <p>
                      Notification Permission - allow the application to show system notifications.
                    </p>
                  </div>
                  <h4 className='mt-3 fw-normal'>Notify me about...</h4>
                  <Formik
                    initialValues={formikData}
                    enableReinitialize
                    onSubmit={async (values, {setSubmitting}) => {
                      console.log('values', values)
                      try {
                        const responseData: any = await ApiPut(
                          API_ROUTES.UserSettingsChange,
                          values
                        )

                        toast.success(responseData?.message)
                        setSubmitting(false)
                      } catch (error: any) {
                        toast.error(error?.error?.message)
                        setSubmitting(false)
                      }
                    }}
                  >
                    {({values, setFieldValue, handleSubmit, isSubmitting}) => (
                      <form onSubmit={handleSubmit}>
                        <div
                          className='form-group two-factor__grey gap-2'
                          style={{
                            justifyContent: 'flex-start',
                          }}
                        >
                          <div className='form-check form-switch form-switch-lg'>
                            <input
                              className='form-check-input'
                              type='checkbox'
                              id='sms-notification'
                              onChange={(e) => {
                                setFieldValue('notificationFlag', e.target.checked)
                              }}
                              checked={values.notificationFlag}
                            />
                            <label className='form-check-label' htmlFor='sms-notification'></label>
                          </div>
                          <span>Notification messages</span>
                        </div>

                        <Button isSubmitting={isSubmitting} />
                        {/* <div
                      className='form-group two-factor__grey gap-2'
                      style={{
                        justifyContent: 'flex-start',
                      }}
                    >
                      <span>Switch</span>
                      <span>Meeting requests</span>
                    </div> */}
                      </form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  )
}

export default SystemNotifications
