import React from 'react'
import Sidebar from '../../Pages/Setting/Sidebar'
import {Outlet} from 'react-router-dom'

const SettingLayout = () => {
  return (
    <div className='d-flex'>
      <Sidebar />
      <Outlet />
    </div>
  )
}

export default SettingLayout
