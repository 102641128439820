// Copyright 2020-2021 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: MIT-0

import React from 'react'
import {VideoTileGrid, UserActivityProvider} from 'amazon-chime-sdk-component-library-react'

import {StyledLayout, StyledContent} from './Styled'
import {useNavigation} from '../../providers/NavigationProvider'
import useMeetingEndRedirect from '../../hooks/useMeetingEndRedirect'
import {useAppState} from '../../providers/AppStateProvider'
import {Layout, MeetingMode} from '../../../../Types/chimeTypes'
import {DataMessagesProvider} from '../../providers/DataMessagesProvider'
import {VideoTileGridProvider} from '../../providers/VideoTileGridProvider'
import MeetingDetails from '../../container/MeetingDetails'
import DynamicMeetingControls from '../../container/DynamicMeetingControls'
import MeetingControls from '../../container/MeetingControls'
import MeetingStatusNotifier from '../../container/MeetingStatusNotifier'
import NavigationControl from '../../container/Navigation/NavigationControl'

const MeetingView = (props: {mode: MeetingMode}) => {
  useMeetingEndRedirect()
  const {showNavbar, showRoster, showChat, showTranscript} = useNavigation()
  const {mode} = props
  const {layout} = useAppState()

  return (
    <UserActivityProvider>
      <DataMessagesProvider>
        <VideoTileGridProvider>
          <StyledLayout
            showNav={showNavbar}
            showRoster={showRoster}
            showChat={showChat}
            showTranscript={showTranscript}
          >
            <StyledContent>
              <VideoTileGrid
                layout={layout === Layout.Gallery ? 'standard' : 'featured'}
                className='videos'
                noRemoteVideoView={<MeetingDetails />}
              />
              {mode === MeetingMode.Spectator ? <DynamicMeetingControls /> : <MeetingControls />}
            </StyledContent>
            <MeetingStatusNotifier />
            <NavigationControl />
          </StyledLayout>
        </VideoTileGridProvider>
      </DataMessagesProvider>
    </UserActivityProvider>
  )
}

export default MeetingView
