import {ReactNode, createContext, useState} from 'react'
import {User} from '../Types'
import {STORAGE_KEY} from '../Constants'
import {useLocalStorage} from '../Hooks/useLocalStorage'

interface AuthContextType {
  user: User | null
  setUser: (user: User | null) => void
}

const AuthContext = createContext<AuthContextType>({
  user: null,
  setUser: () => {},
})

const AuthProvider = ({children}: {children: ReactNode}) => {
  const {getItem} = useLocalStorage()
  const [user, setUser] = useState<User | null>(JSON.parse(getItem(STORAGE_KEY.User) ?? 'null'))

  return (
    <AuthContext.Provider
      value={{
        user,
        setUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export {AuthContext, AuthProvider}
