import {Outlet, Navigate, useLocation} from 'react-router-dom'
import {useAuth} from '../Hooks/useAuth'
import {KEYWORDS, ROUTES} from '../Constants'

const PrivateRoutes = () => {
  const {user} = useAuth()
  const {pathname} = useLocation()
  if (user?.role?.name === KEYWORDS.Roles.SuperAdmin) {
    return user?.token ? (
      pathname.startsWith(`/${ROUTES.Admin}`) ? (
        <Outlet />
      ) : (
        <Navigate to={`/${ROUTES.Admin}/${ROUTES.AdminDashboard}`} />
      )
    ) : (
      <Navigate to={`/${ROUTES.Admin}/${ROUTES.AdminLogin}`} />
    )
  }
  return user?.token ? (
    pathname.startsWith(`/${ROUTES.User}`) ? (
      <Outlet />
    ) : (
      <Navigate to={`/${ROUTES.User}/${ROUTES.Home}`} />
    )
  ) : (
    <Navigate to={`/${ROUTES.User}/${ROUTES.Login}`} />
  )
}

export default PrivateRoutes
