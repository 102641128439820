import React from 'react'
import {ErrorMessage, Formik} from 'formik'
import * as Yup from 'yup'
import {toast} from 'react-toastify'
import Button from '../../Components/Button'
import {ApiPut} from '../../service/api'
import {API_ROUTES} from '../../Constants'

const AdminPassword = () => {
  const intialData = {
    currentPassword: '',
    newPassword: '',
    cPassword: '',
  }

  const Schema = Yup.object().shape({
    currentPassword: Yup.string()
      .min(8, 'Password must be at least 8 characters')
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]+$/,
        'Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character'
      )
      .required('Password is required'),
    newPassword: Yup.string()
      .min(8, 'Password must be at least 8 characters')
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]+$/,
        'Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character'
      )
      .required('Password is required'),
    cPassword: Yup.string()
      .test('passwords-match', 'Passwords must match', function (value) {
        return this.parent.newPassword === value
      })
      .required('Confirm password is required'),
  })
  return (
    <div id='content' className='p-3 pt-1'>
      <div className='container '>
        <div className='card'>
          <div className='card-header'>
            <h2 className='mb-0'>Change Password </h2>
          </div>
          <div className='card-body'>
            <Formik
              initialValues={intialData}
              validationSchema={Schema}
              onSubmit={async (values, {setSubmitting}) => {
                const {cPassword, ...rest} = values
                try {
                  const responseData: any = await ApiPut(API_ROUTES.AdminChangePassword, rest)
                  toast.success(responseData?.message)
                  setSubmitting(false)
                } catch (error: any) {
                  toast.error(error?.error?.message)
                  setSubmitting(false)
                }
              }}
            >
              {({values, handleChange, handleBlur, handleSubmit, isSubmitting}) => (
                <form onSubmit={handleSubmit}>
                  <div className='form-group'>
                    <label htmlFor='price'>Current Password:</label>
                    <input
                      type='password'
                      className='form-control elegant-input'
                      id='currentPassword'
                      name='currentPassword'
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.currentPassword}
                    />
                    <ErrorMessage name='currentPassword' component='div' className='red-error' />
                  </div>
                  <div className='form-group'>
                    <label htmlFor='price'>New Password:</label>
                    <input
                      type='password'
                      className='form-control elegant-input'
                      id='newPassword'
                      name='newPassword'
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.newPassword}
                    />
                    <ErrorMessage name='newPassword' component='div' className='red-error' />
                  </div>
                  <div className='form-group'>
                    <label htmlFor='price'>Confirm New Password:</label>
                    <input
                      type='password'
                      className='form-control elegant-input'
                      id='cPassword'
                      name='cPassword'
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.cPassword}
                    />
                    <ErrorMessage name='cPassword' component='div' className='red-error' />
                  </div>

                  <Button isSubmitting={isSubmitting} text='Change Password' />
                </form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AdminPassword
